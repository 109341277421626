import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Signout: React.FC = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    useEffect(() => {
        const signOutUser = async () => {
            try {
                await logout();
                navigate('/login');
            } catch (error) {
                console.error('Sign out error:', error);
                navigate('/login');
            }
        };

        signOutUser();
    }, [navigate, logout]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="text-gray-600">Signing out...</div>
        </div>
    );
};

export default Signout;