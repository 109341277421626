export interface InsightsDataResult {
  total_products: number;
  new_tags_today: string[];
  category_trends: {
    category: string;
    day: string; // Assuming the day is returned as a string
    count: number;
  }[];
  region_trends: {
    region: string;
    day: string;
    count: number;
  }[];
  industry_trends: {
    industry: string;
    day: string;
    count: number;
  }[];
  source_trends: {
    source: string;
    day: string;
    count: number;
  }[];
  tag_trends: {
    tag: string;
    day: string;
    count: number;
  }[];
  author_trends: {
    author: string;
    day: string;
    count: number;
  }[];
}

export const INSIGHTS_DATA_QUERY = `
  SELECT
    -- Total Products
    (SELECT COUNT(*) FROM products) AS total_products,

    -- Category Trends Over the Last 7 Days
(SELECT JSON_AGG(t.*)
 FROM (
   SELECT
     category,
     day,
     count
   FROM (
     SELECT
       category,
       date_trunc('day', timestamp) AS day,
       COUNT(*) AS count,
       ROW_NUMBER() OVER (PARTITION BY date_trunc('day', timestamp) ORDER BY COUNT(*) DESC) AS rank
     FROM products
     WHERE timestamp >= NOW() - INTERVAL '7 days'
     GROUP BY category, day
   ) ranked
   WHERE rank <= 20
 ) t) AS category_trends,

-- Region Trends Over the Last 7 Days
(SELECT JSON_AGG(t.*)
 FROM (
   SELECT
     region,
     day,
     count
   FROM (
     SELECT
       r.name AS region,
       date_trunc('day', pr.timestamp) AS day,
       COUNT(*) AS count,
       ROW_NUMBER() OVER (PARTITION BY date_trunc('day', pr.timestamp) ORDER BY COUNT(*) DESC) AS rank
     FROM product_region pr
     JOIN region r ON r.id = pr.region_id
     WHERE pr.timestamp >= NOW() - INTERVAL '7 days'
     GROUP BY r.name, day
   ) ranked
   WHERE rank <= 20
 ) t) AS region_trends,

-- Industry Trends Over the Last 7 Days
(SELECT JSON_AGG(t.*)
 FROM (
   SELECT
     industry,
     day,
     count
   FROM (
     SELECT
       i.name AS industry,
       date_trunc('day', pi.timestamp) AS day,
       COUNT(*) AS count,
       ROW_NUMBER() OVER (PARTITION BY date_trunc('day', pi.timestamp) ORDER BY COUNT(*) DESC) AS rank
     FROM product_industry pi
     JOIN industry i ON i.id = pi.industry_id
     WHERE pi.timestamp >= NOW() - INTERVAL '7 days'
     GROUP BY i.name, day
   ) ranked
   WHERE rank <= 20
 ) t) AS industry_trends,

-- Source Trends Over the Last 7 Days
(SELECT JSON_AGG(t.*)
 FROM (
   SELECT
     source,
     day,
     count
   FROM (
     SELECT
       source,
       date_trunc('day', timestamp) AS day,
       COUNT(*) AS count,
       ROW_NUMBER() OVER (PARTITION BY date_trunc('day', timestamp) ORDER BY COUNT(*) DESC) AS rank
     FROM products
     WHERE timestamp >= NOW() - INTERVAL '7 days'
     GROUP BY source, day
   ) ranked
   WHERE rank <= 20
 ) t) AS source_trends,

-- Tag Trends Over the Last 7 Days
(SELECT JSON_AGG(t.*)
 FROM (
   SELECT
     tag,
     day,
     count
   FROM (
     SELECT
       t.name AS tag,
       date_trunc('day', pt.timestamp) AS day,
       COUNT(*) AS count,
       ROW_NUMBER() OVER (PARTITION BY date_trunc('day', pt.timestamp) ORDER BY COUNT(*) DESC) AS rank
     FROM product_tags pt
     JOIN tags t ON t.id = pt.tag_id
     WHERE pt.timestamp >= NOW() - INTERVAL '7 days'
     GROUP BY t.name, day
   ) ranked
   WHERE rank <= 20
 ) t) AS tag_trends,

-- Author Trends Over the Last 7 Days
(SELECT JSON_AGG(t.*)
 FROM (
   SELECT
     author,
     day,
     count
   FROM (
     SELECT
       a.name AS author,
       date_trunc('day', pa.timestamp) AS day,
       COUNT(*) AS count,
       ROW_NUMBER() OVER (PARTITION BY date_trunc('day', pa.timestamp) ORDER BY COUNT(*) DESC) AS rank
     FROM product_author pa
     JOIN author a ON a.id = pa.author_id
     WHERE pa.timestamp >= NOW() - INTERVAL '7 days'
     GROUP BY a.name, day
   ) ranked
   WHERE rank <= 20
 ) t) AS author_trends
`;