import React from 'react';
import ReactECharts from 'echarts-for-react';
import { generateCustomPaletteColors } from '../../shared/colorUtils';
import { useFilterStore } from '../../stores/useFilterStore';
import { ProductAttribute } from '../../shared/types';

interface FunnelChartProps {
    data: any;
    attributeKey: ProductAttribute;
}

const FunnelChart: React.FC<FunnelChartProps> = ({ data, attributeKey }) => {
    const { addFilter } = useFilterStore();
    const seriesData = data?.series && data.series[0]?.data ? data.series[0].data : [];
    const colors = generateCustomPaletteColors(seriesData.length);

    const chartOptions = {
        ...data,
        grid: {
            right: '25%',
            left: '15%',
            containLabel: true
        },
        legend: {
            orient: 'vertical',
            right: '0%',
            top: 'middle',
            width: '20%',
            type: 'scroll',
            itemWidth: 14,
            itemHeight: 14,
            textStyle: {
                overflow: 'truncate',
                width: 100,
                color: 'var(--foreground)',
                rich: {
                    tooltip: {
                        color: 'var(--foreground)',
                        fontSize: 12,
                        width: 80,
                        ellipsis: true,
                        overflow: 'break',
                    },
                },
            },
            formatter: (name: string) => `{tooltip|${name}}`,
        },
        color: colors,
        series: [
            {
                type: 'funnel',
                left: '15%',
                right: '30%',
                width: '55%',
                minSize: '0%',
                maxSize: '70%',
                gap: 2,
                data: seriesData.map((item: any, index: number) => ({
                    name: item.name,
                    value: item.value,
                    itemStyle: {
                        color: colors[index],
                    },
                    label: {
                        position: 'left',
                        color: 'var(--foreground)',
                        formatter: '{b}: {c}',
                        distance: 10,
                    },
                })),
            },
        ],
        tooltip: {
            trigger: 'item',
            formatter: (params: any) => `${params.name}: ${params.value} (${params.percent}%)`,
            textStyle: {
                color: 'var(--foreground)',
            },
        },
    };

    const onEvents = {
        dblclick: (params: any) => {
            if (params && params.name) {
                addFilter(attributeKey, params.name);
            }
        },
    };

    return <ReactECharts option={chartOptions} style={{ height: 300 }} onEvents={onEvents} />;
};

export default FunnelChart;