import React, { useState } from 'react';
import { Button } from '../../ui-kit/button';
import { useFilterStore } from '../../stores/useFilterStore';
import FilterBasic from './FilterBasic';
import FilterQL from './FilterQL';
import { cn } from '../../lib/utils';
import { SavedFiltersMenu } from './SavedFiltersMenu';

const FilterPanel: React.FC = () => {
  const { setProductFilter } = useFilterStore();
  const [viewMode, setViewMode] = useState<'basic' | 'ql'>('basic');

  return (
    <div className="filter-panel">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold">Filters</h3>
      </div>

      <div className="flex items-start justify-between">
        <div className="flex-grow">
          {viewMode === 'basic' ? <FilterBasic /> : <FilterQL />}
        </div>
        
        <div className="flex flex-col gap-2 ml-4">
          <div className="flex items-center gap-2">
            <div className="inline-flex rounded-md">
              <Button
                variant="outline"
                size="sm"
                className={cn(
                  "rounded-r-none",
                  viewMode === 'basic' && "bg-accent text-accent-foreground"
                )}
                onClick={() => setViewMode('basic')}
              >
                Basic
              </Button>
              <Button 
                variant="outline" 
                size="sm"
                className={cn(
                  "rounded-l-none border-l-0",
                  viewMode === 'ql' && "bg-accent text-accent-foreground"
                )}
                onClick={() => setViewMode('ql')}
              >
                QL
              </Button>
            </div>
            <Button
              variant="default"
              size="sm"
              onClick={() => setProductFilter({})}
            >
              Clear All
            </Button>
          </div>
          <div className="flex justify-end">
            <SavedFiltersMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;
