import React, { useState, useEffect } from 'react';
import { Button } from '../ui-kit/button';
import { useSavedFilterStore, SavedFilter, SavedFilterWithIntegrations } from '../stores/useSavedFilterStore';
import { Edit2, Save, X, Clock, Trash2 } from 'lucide-react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui-kit/accordian";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../ui-kit/alert-dialog";

interface EditingFilter extends SavedFilterWithIntegrations {
  id: string;
  name: string;
  description?: string;
  filter_config: SavedFilter['filter_config'];
  created_at: string;
  updated_at: string;
  isEditing: boolean;
}

export const SavedFiltersManager: React.FC = () => {
  const { filters, fetchFilters, updateFilter, deleteFilter } = useSavedFilterStore();
  const [editingFilters, setEditingFilters] = useState<Record<string, EditingFilter>>({});
  const [filterToDelete, setFilterToDelete] = useState<string | null>(null);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  useEffect(() => {
    const initialEditingState = filters.reduce((acc, filter) => ({
      ...acc,
      [filter.id]: { ...filter, isEditing: false }
    }), {});
    setEditingFilters(initialEditingState);
  }, [filters]);

  const handleEdit = (filterId: string) => {
    setEditingFilters(prev => ({
      ...prev,
      [filterId]: { ...prev[filterId], isEditing: true }
    }));
  };

  const handleSave = async (filterId: string) => {
    try {
      const filter = editingFilters[filterId];
      await updateFilter(filterId, {
        name: filter.name,
        description: filter.description,
        filter_config: filter.filter_config
      });
      setEditingFilters(prev => ({
        ...prev,
        [filterId]: { ...prev[filterId], isEditing: false }
      }));
    } catch (error) {
      console.error('Failed to update filter:', error);
    }
  };

  const handleCancel = (filterId: string) => {
    const originalFilter = filters.find(f => f.id === filterId);
    if (originalFilter) {
      setEditingFilters(prev => ({
        ...prev,
        [filterId]: { ...originalFilter, isEditing: false }
      }));
    }
  };

  const handleChange = (filterId: string, field: keyof SavedFilter, value: any) => {
    setEditingFilters(prev => ({
      ...prev,
      [filterId]: { ...prev[filterId], [field]: value }
    }));
  };

  const handleDelete = async (filterId: string) => {
    try {
      await deleteFilter(filterId);
      setFilterToDelete(null);
    } catch (error) {
      console.error('Failed to delete filter:', error);
    }
  };

  return (
    <Accordion type="single" collapsible className="space-y-2">
      {Object.values(editingFilters).map((filter) => (
        <AccordionItem key={filter.id} value={filter.id} className="border rounded-lg">
          <AccordionTrigger className="px-4 py-2 hover:no-underline">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-4">
                <h3 className="font-medium">{filter.name}</h3>
                {filter.description && (
                  <span className="text-sm text-muted-foreground">
                    {filter.description}
                  </span>
                )}
              </div>
              <div className="flex items-center gap-2">
                {filter.integrations?.length > 0 && (
                  <span className="text-xs text-muted-foreground">
                    {filter.integrations.length} Integration{filter.integrations.length !== 1 ? 's' : ''}
                  </span>
                )}
              </div>
            </div>
          </AccordionTrigger>

          <AccordionContent className="px-4 pb-4">
            <div className="space-y-4">
              {/* Edit/View Mode Controls */}
              <div className="flex justify-end">
                {filter.isEditing ? (
                  <div className="flex gap-2">
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() => handleSave(filter.id)}
                    >
                      <Save className="h-4 w-4 mr-1" />
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() => handleCancel(filter.id)}
                    >
                      <X className="h-4 w-4 mr-1" />
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <div className="flex gap-2">
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() => setFilterToDelete(filter.id)}
                    >
                      <Trash2 className="h-4 w-4 mr-1" />
                      Delete
                    </Button>
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() => handleEdit(filter.id)}
                    >
                      <Edit2 className="h-4 w-4 mr-1" />
                      Edit
                    </Button>
                  </div>
                )}
              </div>

              {/* Filter Details */}
              <div className="space-y-3">
                {filter.isEditing ? (
                  <>
                    <div>
                      <label className="text-sm font-medium">Name</label>
                      <input
                        type="text"
                        value={filter.name}
                        onChange={(e) => handleChange(filter.id, 'name', e.target.value)}
                        className="w-full p-1 border rounded mt-1"
                      />
                    </div>
                    <div>
                      <label className="text-sm font-medium">Description</label>
                      <textarea
                        value={filter.description || ''}
                        onChange={(e) => handleChange(filter.id, 'description', e.target.value)}
                        className="w-full p-1 border rounded mt-1"
                        rows={2}
                      />
                    </div>
                  </>
                ) : (
                  <div className="text-sm text-muted-foreground">
                    Last updated: {new Date(filter.updated_at).toLocaleString()}
                  </div>
                )}

                {/* Filter Configuration */}
                <div>
                  <h4 className="text-sm font-medium mb-1">Configuration</h4>
                  {filter.isEditing ? (
                    <textarea
                      value={JSON.stringify(filter.filter_config, null, 2)}
                      onChange={(e) => {
                        try {
                          const newConfig = JSON.parse(e.target.value);
                          handleChange(filter.id, 'filter_config', newConfig);
                        } catch (error) {
                          // Invalid JSON, don't update
                        }
                      }}
                      className="w-full p-1 border rounded font-mono text-xs"
                      rows={5}
                    />
                  ) : (
                    <pre className="bg-muted p-2 rounded text-xs overflow-x-auto">
                      {JSON.stringify(filter.filter_config, null, 2)}
                    </pre>
                  )}
                </div>

                {/* Integrations List */}
                {filter.integrations && filter.integrations.length > 0 && (
                  <div>
                    <h4 className="text-sm font-medium mb-1">Assigned Integrations</h4>
                    <div className="space-y-1 bg-muted rounded-md p-2">
                      {filter.integrations.map((integration) => (
                        <div 
                          key={`${integration.integration_config.integration_type}-${integration.integration_config.integration_name}`}
                          className="flex items-center justify-between text-sm"
                        >
                          <div>
                            <span>{integration.integration_config.integration_name}</span>
                            <span className="text-xs text-muted-foreground ml-2">
                              ({integration.integration_config.integration_type})
                            </span>
                          </div>
                          <div className="flex items-center gap-1 text-xs text-muted-foreground">
                            <Clock className="h-3 w-3" />
                            <span>
                              {Math.floor(integration.run_interval_minutes / (24 * 60))}d {' '}
                              {Math.floor((integration.run_interval_minutes % (24 * 60)) / 60)}h {' '}
                              {integration.run_interval_minutes % 60}m
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      ))}

      {/* Add Alert Dialog */}
      <AlertDialog open={filterToDelete !== null} onOpenChange={() => setFilterToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete this saved filter and remove it from all integrations.
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => filterToDelete && handleDelete(filterToDelete)}
              className="bg-red-600 hover:bg-red-700"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Accordion>
  );
}; 