import { ApiSearchFilter } from '../shared/types';
import { apiKey, apiUrl } from '../shared/constants';

if (!apiKey || !apiUrl) {
  console.warn('Missing required environment variables');
}

const headers = {
  'Content-Type': 'application/json',
  'x-api-key': apiKey,
};

const mode = 'cors';

export const pgQuery = async (queryText: string, values: any[]): Promise<any> => {
  try {
    const response = await fetch(apiUrl + '/pg-query', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
      body: JSON.stringify({ queryText, values }),
      mode,
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`API request failed with status ${response.status}: ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      console.error('This error often occurs due to CORS issues or network problems.');
      console.error('Please check your network connection and ensure the API endpoint is correct and accessible.');
    }
    throw error;
  }
}

export const searchProducts = async (filter: ApiSearchFilter): Promise<any> => {
  try {
    const response = await fetch(apiUrl + '/product/search', {
      method: 'POST',
      headers,
      body: JSON.stringify(filter),
      mode,
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`API request failed with status ${response.status}: ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      console.error('This error often occurs due to CORS issues or network problems.');
      console.error('Please check your network connection and ensure the API endpoint is correct and accessible.');
    }
    throw error;
  }
}
