import React from 'react';
import { useAuth } from '../context/AuthContext';

interface PlanFeature {
    name: string;
    included: boolean;
}

interface PricingPlan {
    name: string;
    price: number | null;
    interval: 'month' | 'year';
    features: PlanFeature[];
    buttonText: string;
    isPopular?: boolean;
}

const plans: PricingPlan[] = [
    {
        name: 'Community',
        price: 0,
        interval: 'month',
        features: [
            { name: 'Dashboard Access', included: true },
            { name: 'Non-Commercial Use', included: true },
            { name: 'Basic Threat Intelligence', included: true },
            { name: 'Community Discussions', included: true },
            { name: 'Security API Access', included: false },
        ],
        buttonText: 'Join Community',
    },
    {
        name: 'Business',
        price: 50,
        interval: 'month',
        features: [
            { name: 'Dashboard Access', included: true },
            { name: 'Priority Support Channel', included: true },
            { name: 'Security API Access', included: true },
            { name: 'Contributor Subscriptions', included: true },
            { name: 'Commercial Use', included: true },
        ],
        buttonText: 'Partner With Us',
        isPopular: true,
    },
    {
        name: 'Enterprise Partner',
        price: null,
        interval: 'month',
        features: [
            { name: 'Tiered Security API Access', included: true },
            { name: 'Transactional Pricing Model', included: true },
            { name: 'Priority Support Channel', included: true },
            { name: 'Contributor Subscriptions', included: true },
            { name: 'Supporting Teams/Organizations', included: true }, 
        ],
        buttonText: 'Partner With Us',
    },
];

const BillingPage: React.FC = () => {
    const { isAuthenticated } = useAuth();

    const handleUpgrade = (planName: string) => {
        if (planName === 'Community') {
            window.location.href = 'https://discord.com/invite/zerVhHtfxJ';
        } else {
            window.location.href = 'mailto:admin@truss-security.com';
        }
    };

    return (
        <div className="bg-gray-50 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
            {/* Header */}
            <div className="text-center mb-12">
                <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
                    Join the Truss Community
                </h1>
                <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
                    Choose how you want to participate in our security ecosystem
                </p>
            </div>

            {/* Pricing Cards */}
            <div className="max-w-7xl mx-auto grid gap-8 lg:grid-cols-3 justify-center">
                {plans.map((plan) => (
                    <div
                        key={plan.name}
                        className={`relative bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden
                            ${plan.isPopular ? 'ring-2 ring-blue-500' : ''}`}
                    >
                        {plan.isPopular && (
                            <div className="absolute top-0 right-0 bg-blue-500 text-white px-4 py-1 text-sm">
                                Popular
                            </div>
                        )}

                        <div className="p-8">
                            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                                {plan.name}
                            </h2>
                            <p className="mt-4">
                                <span className="text-4xl font-extrabold text-gray-900 dark:text-white">
                                    ${plan.price}
                                </span>
                                <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                                    /{plan.interval}
                                </span>
                            </p>

                            {/* Features */}
                            <ul className="mt-8 space-y-4">
                                {plan.features.map((feature) => (
                                    <li
                                        key={feature.name}
                                        className="flex items-center"
                                    >
                                        {feature.included && !feature.name.includes('Coming Soon') && (
                                            <svg className="h-5 w-5 text-green-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                <path d="M5 13l4 4L19 7"></path>
                                            </svg>
                                        )}
                                        {!feature.included && (
                                            <svg className="h-5 w-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                <path d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                        )}
                                        <span className={`ml-3 ${feature.name.includes('Coming Soon')
                                                ? 'bg-yellow-100 dark:bg-yellow-900 px-2 py-1 rounded text-yellow-800 dark:text-yellow-200'
                                                : feature.included
                                                    ? 'text-gray-700 dark:text-gray-300'
                                                    : 'text-gray-500 dark:text-gray-400'
                                            }`}>
                                            {feature.name}
                                        </span>
                                    </li>
                                ))}
                            </ul>

                            {/* CTA Button */}
                            <button
                                onClick={() => handleUpgrade(plan.name)}
                                className={`mt-8 w-full py-3 px-4 rounded-md font-medium text-white
                                    ${plan.isPopular
                                        ? 'bg-blue-500 hover:bg-blue-600'
                                        : 'bg-gray-800 dark:bg-gray-700 hover:bg-gray-900 dark:hover:bg-gray-600'}`}
                            >
                                {plan.buttonText}
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {/* Beta Testing Note */}
            <div className="text-center mt-12 mb-8">
                <p className="text-md text-gray-500 dark:text-gray-400 italic">
                    * During Beta Testing, all testers have the equivalent of the Enterprise account.
                </p>
            </div>

            {/* Contribution Box */}
            <div className="max-w-4xl mx-auto mt-12 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
                <div className="text-center mb-6">
                    <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                        Would you like to contribute to Truss Security?
                    </h3>
                    <div className="grid grid-cols-3 gap-6 mb-8">
                        <div className="text-center">
                            <div className="text-blue-500 text-4xl mb-2">🤝</div>
                            <h4 className="font-semibold mb-2">Collaborate</h4>
                            <p className="text-sm text-gray-600 dark:text-gray-300">
                                Share your security insights
                            </p>
                        </div>
                        <div className="text-center">
                            <div className="text-blue-500 text-4xl mb-2">🚀</div>
                            <h4 className="font-semibold mb-2">Earn Rewards</h4>
                            <p className="text-sm text-gray-600 dark:text-gray-300">
                                Earn rewards for your contributions
                            </p>
                        </div>
                        <div className="text-center">
                            <div className="text-blue-500 text-4xl mb-2">🌎</div>
                            <h4 className="font-semibold mb-2">Global Protection</h4>
                            <p className="text-sm text-gray-600 dark:text-gray-300">
                                Strengthen global cybersecurity
                            </p>
                        </div>
                    </div>
                    <p className="text-gray-600 dark:text-gray-300 leading-relaxed mb-6">
                        Contribute to Truss Security as we build the future of collaborative security!
                        The Truss Security ecosystem enables rapid threat intelligence sharing, making the digital
                        world safer for everyone through collective defense.
                    </p>
                    <button
                        onClick={() => handleUpgrade('Business')}
                        className="px-8 py-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md font-medium"
                    >
                        Start the Conversation
                    </button>
                </div>
            </div>

            {/* Current Subscription Info (if authenticated) */}
            {isAuthenticated && (
                <div className="mt-12 max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-lg shadow p-6">
                    <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
                        Current Subscription
                    </h2>
                    <div className="space-y-4">
                        <div className="flex justify-between">
                            <span className="text-gray-600 dark:text-gray-300">Plan</span>
                            <span className="font-medium text-gray-900 dark:text-white">Business</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-gray-600 dark:text-gray-300">Billing Period</span>
                            <span className="font-medium text-gray-900 dark:text-white">Monthly</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-gray-600 dark:text-gray-300">Next Payment</span>
                            <span className="font-medium text-gray-900 dark:text-white">N/A</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BillingPage;