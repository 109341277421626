import * as React from "react";
import { SidebarProvider, SidebarTrigger } from "../ui-kit/sidebar";
import { AppSidebar } from "./AppSidebar";
import { useAuth } from "../context/AuthContext";
import { useLocation } from "react-router-dom";
import FilterPanel from "./filter/FilterPanel";

export default function AppSidebarLayout({ children }: { children: React.ReactNode }) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const noSidebarPaths = ['/login', '/signout', '/callback'];
  const showFilters = ['/charts', '/products', '/api'].includes(location.pathname);
  const shouldShowSidebar = isAuthenticated && !noSidebarPaths.includes(location.pathname);

  if (!shouldShowSidebar) {
    return <main className="flex-1 overflow-auto bg-background">{children}</main>;
  }

  return (
    <SidebarProvider>
      <AppSidebar />
      <main className="flex-1 overflow-auto bg-background">
        <SidebarTrigger />
        <div className="container mx-auto p-4">
          {showFilters && (
            <div className="top-0 z-10 bg-background pb-4">
              <FilterPanel />
            </div>
          )}
          {children}
        </div>
      </main>
    </SidebarProvider>
  );
} 