import { create } from 'zustand'
import { getFilteredProductIds, getFilteredProductCount } from '../services/pgService';
import { IdSet } from '../shared/types';
import { useFilterStore } from './useFilterStore';
import { useDateRangeStore } from './useDateRangeStore';

interface ProductIdStore {
  count: number;
  productIdPages: Record<number, IdSet[]>;
  currentPage: number;
  isLoading: boolean;
  error: string | null;
  fetchProductIds: (page: number) => Promise<void>;
}

export const useProductIdStore = create<ProductIdStore>((set) => ({
  count: 0,
  productIdPages: {},
  currentPage: 1,
  isLoading: false,
  error: null,
  fetchProductIds: async (page: number) => {
    if (page !== 1 && useProductIdStore.getState().productIdPages[page]) {
      return;
    }
    
    set({ isLoading: true, error: null });
    try {
      const filter = useFilterStore.getState().productFilter;
      const dateRange = useDateRangeStore.getState().dateRange;
      
      const count = await getFilteredProductCount(filter, dateRange.from, dateRange.to);
      console.log('getFilteredProductCount', count);
      const productIds = await getFilteredProductIds(filter, dateRange.from, dateRange.to, page);
      console.log('getFilteredProductIds', productIds);
      
      set({
        count,
        productIdPages: { [page]: productIds },
        currentPage: page,
        isLoading: false
      });
    } catch (error) {
      set({ error: (error as Error).message, isLoading: false });
    }
  },
}));

// Update when filter changes
useFilterStore.subscribe(
  (state) => {
    if (state.productFilter) {
      useProductIdStore.setState({ productIdPages: {} });
      useProductIdStore.getState().fetchProductIds(1);
    }
  }
);

// Add subscription for date range changes
useDateRangeStore.subscribe(
  (state) => {
    if (state.dateRange) {
      useProductIdStore.setState({ productIdPages: {} });
      useProductIdStore.getState().fetchProductIds(1);
    }
  }
);