import React, { useState, FormEvent } from 'react';

interface ReauthModalProps {
    onClose: () => void;
    onReauthSuccess: () => void;
}

const ReauthModal: React.FC<ReauthModalProps> = ({ onClose, onReauthSuccess }) => {
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');

    const handleReauth = async (e: FormEvent) => {
        e.preventDefault();
        try {
            if (password) {
                onReauthSuccess();
                onClose();
            } else {
                setError('Password is required');
            }
        } catch (err) {
            setError('Reauthentication failed. Please try again.');
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" aria-modal="true" role="dialog">
            <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
                <button 
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl"
                    onClick={onClose}
                    aria-label="Close"
                >
                    &times;
                </button>
                <h2 className="text-2xl font-bold mb-6">Confirm Password</h2>
                <form onSubmit={handleReauth} className="space-y-4">
                    <div className="space-y-2">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            Password:
                        </label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                    <button 
                        type="submit"
                        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        Confirm
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ReauthModal;