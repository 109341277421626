import React, { useEffect, useState } from 'react';
import { pgQuery } from '../services/apiService';
import { INSIGHTS_DATA_QUERY, InsightsDataResult } from '../queries/insightsDataQuery';
import { INDICATORS_COUNT_QUERY } from '../queries/indicatorQueries';
import StackedAreaChart from '../components/charts/StackedAreaChart';

export default function InsightsPage() {
  const [data, setData] = useState<InsightsDataResult | null>(null);
  const [indicatorCount, setIndicatorCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const [insightsResult, indicatorsResult] = await Promise.all([
          pgQuery(INSIGHTS_DATA_QUERY, []),
          pgQuery(INDICATORS_COUNT_QUERY, [])
        ]);
        setData(insightsResult[0] as InsightsDataResult);
        setIndicatorCount(indicatorsResult[0].count);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('An error occurred'));
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  if (loading) return <div className="text-muted-foreground">Loading...</div>;
  if (error) return <div className="text-destructive">Error: {error.message}</div>;

  const prepareChartData = (trends: any[], key: string) => {
    const grouped: { [day: string]: { [key: string]: number } } = {};
    const categories = new Set<string>();

    trends.forEach((item) => {
      const { day, count } = item;
      const value = item[key];
      categories.add(value);

      if (!grouped[day]) grouped[day] = {};
      grouped[day][value] = count;
    });

    return Object.keys(grouped)
      .sort()
      .map((day) => {
        const dayData = grouped[day];
        const sortedValues = Object.entries(dayData)
          .sort(([, a], [, b]) => b - a)
          .slice(0, 20)
          .reduce((acc, [value, count]) => ({ ...acc, [value]: count }), {});

        return { day, ...sortedValues };
      });
  };

  return (
    <div className="p-5 space-y-6">
      <h1 className="text-center text-2xl font-bold text-foreground">Insights</h1>
      <div className="grid grid-cols-2 gap-4">
        <div className="p-4 bg-card text-card-foreground text-center shadow-sm rounded-lg border border-border">
          <h2 className="text-lg font-semibold">Total Products</h2>
          <p className="text-2xl">{data?.total_products}</p>
        </div>
        <div className="p-4 bg-card text-card-foreground text-center shadow-sm rounded-lg border border-border">
          <h2 className="text-lg font-semibold">7 Days Total Indicators</h2>
          <p className="text-2xl">{indicatorCount}</p>
        </div>
      </div>
      <StackedAreaChart
        title="Category Trends"
        data={prepareChartData(data?.category_trends || [], 'category')}
        xKey="day"
        yKeys={Array.from(new Set(data?.category_trends.map((item) => item.category)))}
        itemKey="category"
      />
      <StackedAreaChart
        title="Region Trends"
        data={prepareChartData(data?.region_trends || [], 'region')}
        xKey="day"
        yKeys={Array.from(new Set(data?.region_trends.map((item) => item.region)))}
        itemKey="region"
      />
      <StackedAreaChart
        title="Industry Trends"
        data={prepareChartData(data?.industry_trends || [], 'industry')}
        xKey="day"
        yKeys={Array.from(new Set(data?.industry_trends.map((item) => item.industry)))}
        itemKey="industry"
      />
      <StackedAreaChart
        title="Source Trends"
        data={prepareChartData(data?.source_trends || [], 'source')}
        xKey="day"
        yKeys={Array.from(new Set(data?.source_trends.map((item) => item.source)))}
        itemKey="source"
      />
      <StackedAreaChart
        title="Tag Trends"
        data={prepareChartData(data?.tag_trends || [], 'tag')}
        xKey="day"
        yKeys={Array.from(new Set(data?.tag_trends.map((item) => item.tag)))}
        itemKey="tags"
      />
      <StackedAreaChart
        title="Author Trends"
        data={prepareChartData(data?.author_trends || [], 'author')}
        xKey="day"
        yKeys={Array.from(new Set(data?.author_trends.map((item) => item.author)))}
        itemKey="author"
      />
    </div>
  );
}