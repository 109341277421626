import { create } from 'zustand';
import { supabase } from '../lib/supabaseClient';
import { ProductFilter } from '../shared/types';

export interface SavedFilter {
  id: string;
  user_id: string;
  name: string;
  description?: string;
  filter_config: {
    productFilter: ProductFilter;
    dateRange: {
      from: string;
      to: string;
    };
  };
  created_at: string;
  updated_at: string;
}

export interface SavedFilterWithIntegrations extends SavedFilter {
  integrations: {
    integration_config: {
      integration_type: string;
      integration_name: string;
    };
    run_interval_minutes: number;
  }[];
}

interface SavedFilterStore {
  filters: SavedFilterWithIntegrations[];
  isLoading: boolean;
  error: string | null;
  currentUserId: string | null;
  fetchFilters: () => Promise<void>;
  saveFilter: (name: string, description: string, filterConfig: SavedFilter['filter_config']) => Promise<void>;
  updateFilter: (id: string, updates: Partial<SavedFilter>) => Promise<void>;
  deleteFilter: (id: string) => Promise<void>;
  verifyOwnership: (filterId: string) => Promise<boolean>;
}

export const useSavedFilterStore = create<SavedFilterStore>((set, get) => ({
  filters: [],
  isLoading: false,
  error: null,
  currentUserId: null,

  fetchFilters: async () => {
    set({ isLoading: true });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      const { data, error } = await supabase
        .from('saved_filters')
        .select(`
          *,
          integrations:integration_filters(
            run_interval_minutes,
            integration_config:integration_configs(
              integration_type,
              integration_name
            )
          )
        `)
        .eq('user_id', user.id);

      if (error) throw error;
      set({ filters: data || [] });
    } catch (error) {
      console.error('Error fetching filters:', error);
    } finally {
      set({ isLoading: false });
    }
  },

  verifyOwnership: async (filterId: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return false;

      const { data, error } = await supabase
        .from('saved_filters')
        .select('user_id')
        .eq('id', filterId)
        .single();

      if (error || !data) return false;
      return data.user_id === user.id;
    } catch {
      return false;
    }
  },

  saveFilter: async (name, description, filterConfig) => {
    set({ isLoading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      const { productFilter } = filterConfig;
      const cleanedFilterConfig = {
        productFilter
      };

      const { error } = await supabase
        .from('saved_filters')
        .insert({
          user_id: user.id,
          name,
          description,
          filter_config: cleanedFilterConfig,
        });

      if (error) throw error;
      await get().fetchFilters();
    } catch (error) {
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  updateFilter: async (id, updates) => {
    set({ isLoading: true, error: null });
    try {
      const isOwner = await get().verifyOwnership(id);
      if (!isOwner) throw new Error('Unauthorized to update this filter');

      const { error } = await supabase
        .from('saved_filters')
        .update({
          ...updates,
          updated_at: new Date().toISOString(),
        })
        .eq('id', id);

      if (error) throw error;
      await get().fetchFilters();
    } catch (error) {
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  deleteFilter: async (id: string) => {
    set({ isLoading: true, error: null });
    try {
      const isOwner = await get().verifyOwnership(id);
      if (!isOwner) throw new Error('Unauthorized to delete this filter');

      const { error } = await supabase
        .from('saved_filters')
        .delete()
        .eq('id', id);

      if (error) throw error;
      await get().fetchFilters();
    } catch (error) {
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },
})); 