import React, { useState, useEffect } from 'react';
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '../ui-kit/collapsible';
import { Button } from '../ui-kit/button';
import { ChevronDown } from 'lucide-react';

import { PRODUCT_ATTRIBUTES } from '../shared/constants';
import { ProductAttribute } from '../shared/types';
import { useAttributeCountStore } from '../stores/useAttributeCountStore';
import { useFilterStore } from '../stores/useFilterStore';
import { useDateRangeStore } from '../stores/useDateRangeStore';

const FilterSidebar: React.FC = () => {
  const { fullCounts, filteredCounts, isScalarAttributesLoading, isArrayAttributesLoading, hasMoreAttributes } = useAttributeCountStore();
  const { productFilter, addFilter } = useFilterStore();
  const { dateRange } = useDateRangeStore();
  const [isDataReady, setIsDataReady] = useState(false);
  const [openSections, setOpenSections] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (fullCounts) {
      const hasAnyData = Object.values(fullCounts).some(obj => Object.keys(obj).length > 0);
      setIsDataReady(hasAnyData);
    }
  }, [fullCounts, dateRange]);

  const handleValueClick = (attribute: ProductAttribute, value: string) => {
    addFilter(attribute, value);
  };

  const handleToggle = (attribute: string) => {
    setOpenSections(prev => ({
      ...prev,
      [attribute]: !prev[attribute]
    }));
  };

  return (
    <div className="flex flex-col w-full h-screen">
      {isScalarAttributesLoading ? (
        <p className="text-muted-foreground">Fetching data...</p>
      ) : fullCounts && Object.keys(fullCounts).length > 0 ? (
        isDataReady ? (
          <>
            {PRODUCT_ATTRIBUTES.map((attribute) => (
              <Collapsible 
                key={attribute}
                open={openSections[attribute]}
                onOpenChange={() => handleToggle(attribute)}
              >
                <CollapsibleTrigger asChild>
                  <Button variant="ghost" className="w-full flex items-center justify-between px-4">
                    <div className="flex items-center">
                      <ChevronDown 
                        className={`mr-2 h-4 w-4 transition-transform duration-200 ${
                          openSections[attribute] ? '' : '-rotate-90'
                        }`}
                      />
                      {attribute}
                    </div>
                  </Button>
                </CollapsibleTrigger>
                {/* Selected items */}
                {(productFilter[attribute] || []).map((value) => (
                  <li
                    key={`selected-${value}`}
                    onClick={() => handleValueClick(attribute, value)}
                    className="cursor-pointer hover:bg-accent hover:text-accent-foreground p-1 rounded font-bold px-12 list-none text-xs"
                  >
                    <span>{value}: {fullCounts[attribute]?.[value] || 0}</span>{' '}
                    <span>({filteredCounts[attribute]?.[value] || 0})</span>
                  </li>
                ))}
                <CollapsibleContent className="px-11">
                  <ul>
                    {Object.entries(fullCounts[attribute] || {})
                      .sort(([valueA], [valueB]) => {
                        const filteredCountA = filteredCounts[attribute]?.[valueA] || 0;
                        const filteredCountB = filteredCounts[attribute]?.[valueB] || 0;
                        return filteredCountB - filteredCountA;
                      })
                      .filter(([value]) => {
                        const attributeFilter = productFilter[attribute] || [];
                        return !attributeFilter.includes(value) && value !== "";
                      })
                      .map(([value, count]) => {
                        const attributeFilter = productFilter[attribute] || [];
                        const shouldLighten = attributeFilter.length > 0;
                        const filteredCount = filteredCounts[attribute]?.[value] || 0;
                        const textColorClass = filteredCount > 0 ? 'text-foreground' : 'text-muted-foreground';

                        return (
                          <li
                            key={value}
                            onClick={() => handleValueClick(attribute, value)}
                            className={`cursor-pointer hover:bg-accent hover:text-accent-foreground p-1 rounded text-xs
                              ${shouldLighten ? 'text-muted-foreground' : textColorClass}`}
                          >
                            <span className={textColorClass}>{value}: {count}</span>{' '}
                            <span className={textColorClass}>({filteredCount})</span>
                          </li>
                        );
                      })}
                  </ul>
                  {Object.keys(fullCounts[attribute] || {}).length > 0 && hasMoreAttributes[attribute] && (
                    <Button
                      variant="ghost"
                      size="sm"
                      className="w-full text-xs text-muted-foreground hover:text-foreground mt-1"
                      onClick={() => useAttributeCountStore.getState().fetchMoreAttributeCounts(attribute)}
                    >
                      More...
                    </Button>
                  )}
                </CollapsibleContent>
              </Collapsible>
            ))}
          </>
        ) : (
          <p className="text-muted-foreground">No attribute data available. Try adjusting your date range or filters.</p>
        )
      ) : (
        <p className="text-muted-foreground">Attribute data not loaded. Please wait or refresh the page.</p>
      )}
      {isArrayAttributesLoading && <p className="text-muted-foreground">Loading additional data...</p>}
    </div>
  );
}

export default FilterSidebar;