import React from 'react';
import ReactECharts from 'echarts-for-react';
import { generateCustomPaletteColors } from '../../shared/colorUtils';
import { useFilterStore } from '../../stores/useFilterStore';
import { ProductAttribute } from '../../shared/types';

interface PieChartProps {
    data: any;
    attributeKey: ProductAttribute;
}

const PieChart: React.FC<PieChartProps> = ({ data, attributeKey }) => {
    const { addFilter } = useFilterStore();
    const seriesData = data?.series && data.series[0]?.data ? data.series[0].data : [];
    const colors = generateCustomPaletteColors(seriesData.length);

    const truncateLabel = (label: string, maxLength: number = 20) => {
        return label.length > maxLength ? `${label.slice(0, maxLength)}...` : label;
    };

    const chartOptions = {
        legend: {
            orient: 'vertical',
            right: '0%',
            top: 'center',
            width: '20%',
            height: '80%',  // Adjust the legend height to allow more items before scrolling
            type: 'scroll',  // Enables scrolling for legends
            itemWidth: 14,
            itemHeight: 14,
            textStyle: {
                fontSize: 12,
                overflow: 'truncate',
                width: 100,
                color: 'var(--foreground)',  // Add this to use theme text color
            },
            formatter: (name: string) => truncateLabel(name, 20),  // Truncate in legend
            tooltip: { show: true },  // Show tooltip on legend hover
        },
        color: colors,
        series: [
            {
                type: 'pie',
                data: seriesData.map((item: any, index: number) => ({
                    name: item.name,  // Full name used for the tooltip and pie slice label
                    value: item.value,
                    itemStyle: {
                        color: colors[index],
                    },
                })),
                radius: ['0%', '70%'],
                center: ['35%', '50%'],
                label: {
                    show: true,  // Ensure labels are shown on the pie slices
                    formatter: (params: any) => truncateLabel(params.name, 20),  // Truncate the label on the pie slice
                    position: 'outside',  // Position labels outside the slices
                    overflow: 'truncate',
                    color: 'var(--foreground)',  // Use CSS variable for text color
                    textBorderWidth: 0,  // Remove the text border/outline
                    rich: {
                        tooltip: {
                            color: 'var(--foreground)',  // Use CSS variable for tooltip text
                            fontSize: 12,
                            width: 80,
                            ellipsis: true,
                        },
                    },
                },
            },
        ],
        tooltip: {
            trigger: 'item',
            formatter: (params: any) => `${params.data.name}: ${params.data.value} (${params.percent}%)`,  // Full name in tooltip
            textStyle: {
                fontSize: 12,
            },
        },
    };

    const onEvents = {
        'dblclick': (params: any) => {
            if (params && params.name) {
                addFilter(attributeKey, params.name);
            }
        },
    };

    return (
        <ReactECharts
            option={chartOptions}
            style={{ height: 300, width: '100%' }}
            onEvents={onEvents}
        />
    );
};

export default PieChart;