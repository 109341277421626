import React from 'react';
import { Button } from "../ui-kit/button";
import { useUserDataStore } from '../stores/useUserDataStore';
import { TrussUsers } from '../services/supabase';

interface ProfileInformationProps {
    isEditing: boolean;
    setIsEditing: (value: boolean) => void;
    newUsername: string;
    setNewUsername: (value: string) => void;
    setAlertStatus: (status: { isSuccess: boolean; message: string } | null) => void;
    userData: TrussUsers;
}

const ProfileInformation: React.FC<ProfileInformationProps> = ({
    isEditing,
    setIsEditing,
    newUsername,
    setNewUsername,
    setAlertStatus,
    userData,
}) => {
    const { updateUserData, fetchUserData } = useUserDataStore();
    const { username, bio, api_key } = userData;
    
    const [formData, setFormData] = React.useState({
        bio: bio
    });

    React.useEffect(() => {
        if (isEditing) {
            setFormData({
                bio: bio
            });
            setNewUsername(username);
        }
    }, [isEditing]);

    const handleProfileUpdate = async () => {
        try {
            await updateUserData({
                username: newUsername,
                ...formData
            });
            await fetchUserData();
            setIsEditing(false);
            setAlertStatus({
                isSuccess: true,
                message: 'Profile updated successfully'
            });
        } catch (error: any) {
            setAlertStatus({ 
                isSuccess: false, 
                message: `Failed to update profile: ${error.message}` 
            });
        }
    };

    return (
        <div className="bg-background p-6 rounded-lg shadow-md mb-6">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-medium text-foreground">Profile Information</h2>
                <Button 
                    onClick={() => setIsEditing(!isEditing)}
                    variant={isEditing ? "destructive" : "default"}
                >
                    {isEditing ? 'Cancel' : 'Edit Profile'}
                </Button>
            </div>

            {isEditing ? (
                <div className="space-y-4">
                    <div>
                        <label className="block mb-1">Username</label>
                        <input
                            type="text"
                            value={newUsername}
                            onChange={(e) => setNewUsername(e.target.value)}
                            className="w-full p-2 border rounded bg-background text-foreground dark:border-gray-600"
                        />
                    </div>
                    <div>
                        <label className="block mb-1">Bio</label>
                        <textarea
                            value={formData.bio}
                            onChange={(e) => setFormData(prev => ({ ...prev, bio: e.target.value }))}
                            className="w-full p-2 border rounded bg-background text-foreground dark:border-gray-600"
                            rows={3}
                        />
                    </div>
                    <Button 
                        onClick={handleProfileUpdate}
                        variant="default"
                    >
                        Save Changes
                    </Button>
                </div>
            ) : (
                <div className="space-y-4">
                    <p><strong>Username:</strong> {username}</p>
                    <p><strong>Bio:</strong> {bio || 'No bio added'}</p>
                    <div className="break-all">
                        <p><strong>API Key:</strong> <code className="p-1 rounded">{api_key}</code></p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfileInformation; 