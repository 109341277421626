import React from 'react';
import { useDarkMode } from '../hooks/useDarkMode';

interface ChartsPageSelectorProps {
    currentPage: string;
    onPageChange: (page: string) => void;
}

const ChartsPageSelector: React.FC<ChartsPageSelectorProps> = ({ currentPage, onPageChange }) => {
    const { isDarkMode } = useDarkMode();

    return (
        <div className="absolute top-4 right-4">
            <select
                value={currentPage}
                onChange={(e) => onPageChange(e.target.value)}
                className={`
                    p-2 rounded-md border 
                    ${isDarkMode 
                        ? 'bg-gray-800 text-gray-200 border-gray-700' 
                        : 'bg-white text-gray-900 border-gray-300'
                    }
                    hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500
                    transition-colors duration-200
                `}
            >
                <option value="Regional">Regional</option>
                <option value="Source">Source</option>
                <option value="Indicators">Indicators</option>
            </select>
        </div>
    );
};

export default ChartsPageSelector;