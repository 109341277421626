import React, { createContext, useContext, useEffect, useState } from 'react';
import AuthService from './AuthService';
import { User } from '@supabase/supabase-js';
import { supabaseClient } from '../services/supabase';
import { useUserDataStore } from '../stores/useUserDataStore';

interface AuthContextType {
    isAuthenticated: boolean;
    authLoading: boolean;
    checkAuthStatus: () => Promise<boolean>;
    login: (email: string, password: string) => Promise<User>;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authLoading, setAuthLoading] = useState(true);
    const { fetchUserData } = useUserDataStore();

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                const authStatus = await AuthService.checkAuthStatus();
                setIsAuthenticated(authStatus);
            } catch (error) {
                console.error('Auth initialization error:', error);
                setIsAuthenticated(false);
            } finally {
                setAuthLoading(false);
            }
        };

        initializeAuth();
    }, []);

    useEffect(() => {
        const { data: { subscription } } = supabaseClient.auth.onAuthStateChange((event) => {
            if (event === 'SIGNED_IN') {
                setIsAuthenticated(true);
            }
            if (event === 'SIGNED_OUT') {
                setIsAuthenticated(false);
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [fetchUserData]);

    useEffect(() => {
        const sessionCheckInterval = setInterval(async () => {
            const isStillAuthenticated = await checkAuthStatus();
            if (!isStillAuthenticated && isAuthenticated) {
                setIsAuthenticated(false);
            }
        }, 300000); //check for session expire every 5 minutes

        return () => {
            clearInterval(sessionCheckInterval);
        };
    }, [isAuthenticated]);

    const login = async (email: string, password: string): Promise<User> => {
        try {
            const user = await AuthService.login(email, password);
            setIsAuthenticated(true);
            return user;
        } catch (error) {
            setIsAuthenticated(false);
            throw error;
        }
    };

    const logout = async (): Promise<void> => {
        try {
            await AuthService.logout();
            setIsAuthenticated(false);
        } catch (error) {
            console.error('Logout error:', error);
            throw error;
        }
    };

    const checkAuthStatus = async () => {
        try {
            const status = await AuthService.checkAuthStatus();
            if (!status && isAuthenticated) {
                alert('Your session has expired. Please log in again.');
            }
            setIsAuthenticated(status);
            return status;
        } catch (error) {
            console.error('Auth check error:', error);
            setIsAuthenticated(false);
            return false;
        }
    };

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            authLoading,
            checkAuthStatus,
            login,
            logout
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};