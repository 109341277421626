import * as React from "react"
import { cn } from "../lib/utils"
import { Highlight, themes } from "prism-react-renderer"
import { ClipboardIcon, CheckIcon } from "@radix-ui/react-icons"

interface CodeProps {
  language: string
  children: string
  className?: string
}

export function Code({ language, children, className }: CodeProps) {
  const [hasCopied, setHasCopied] = React.useState(false)

  const copyToClipboard = React.useCallback(async () => {
    await navigator.clipboard.writeText(children.trim())
    setHasCopied(true)
    setTimeout(() => setHasCopied(false), 2000)
  }, [children])

  return (
    <div className="relative">
      <button
        onClick={copyToClipboard}
        className="absolute right-4 top-4 hover:bg-zinc-800 p-2 rounded-md transition-colors"
      >
        {hasCopied ? (
          <CheckIcon className="h-4 w-4 text-zinc-400" />
        ) : (
          <ClipboardIcon className="h-4 w-4 text-zinc-400" />
        )}
      </button>
      <Highlight
        theme={themes.vsDark}
        code={children.trim()}
        language={language}
      >
        {({ className: _className, style, tokens, getLineProps, getTokenProps }) => (
          <pre
            className={cn(
              "p-4 rounded-lg overflow-x-auto",
              "bg-zinc-950 text-zinc-50",
              className,
              _className
            )}
            style={style}
          >
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line })}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </div>
  )
}