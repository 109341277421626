import React, { useState } from 'react';
import { useFilterStore } from '../../stores/useFilterStore';
import { PRODUCT_ATTRIBUTES } from '../../shared/constants';
import { ProductAttribute } from '../../shared/types';
import { useAttributeCountStore } from '../../stores/useAttributeCountStore';
import { Button } from '../../ui-kit/button';
import { X } from 'lucide-react';

const FilterBasic: React.FC = () => {
  const { productFilter, setProductFilter, removeFilter, removeFilterGroup } = useFilterStore();
  const [selectedAttribute, setSelectedAttribute] = useState<ProductAttribute | ''>('');
  const { fullCounts, filteredCounts } = useAttributeCountStore();

  const handleRemoveFilterGroup = (attr: ProductAttribute) => {
    removeFilterGroup(attr);
    setSelectedAttribute('');
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-wrap gap-2 items-center">
        {Object.entries(productFilter).map(([key, values]) => (
          <div key={key} className="bg-muted rounded-lg p-2 flex flex-col">
            <div className="flex items-center gap-2">
              <span className="font-medium">{key}</span>
              <Button
                variant="ghost"
                size="sm"
                className="h-6 w-6 p-0"
                onClick={() => handleRemoveFilterGroup(key as ProductAttribute)}
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
            <div className="flex flex-wrap gap-1">
              {values.map((value) => (
                <span 
                  key={value} 
                  className="bg-background px-2 py-1 rounded flex items-center gap-1 text-sm"
                >
                  {value}
                  <Button
                    variant="ghost"
                    size="sm"
                    className="h-4 w-4 p-0"
                    onClick={() => removeFilter(key as ProductAttribute, value)}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </span>
              ))}
              
              <select
                className="h-8 rounded-md border border-input bg-background px-3 py-1 text-sm ring-offset-background 
                          placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring 
                          focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                onChange={(e) => {
                  if (e.target.value) {
                    const newValue = e.target.value;
                    setProductFilter({
                      ...productFilter,
                      [key]: [...values, newValue]
                    });
                    e.target.value = '';
                  }
                }}
                value=""
              >
                <option value="">+</option>
                {Object.entries(fullCounts[key] || {})
                  .sort(([valueA], [valueB]) => {
                    const filteredCountA = filteredCounts[key]?.[valueA] || 0;
                    const filteredCountB = filteredCounts[key]?.[valueB] || 0;
                    return filteredCountB - filteredCountA;
                  })
                  .filter(([value]) => !values.includes(value))
                  .map(([value]) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        ))}
        
        <select
          className="h-8 rounded-md border border-input bg-background px-3 py-1 text-sm ring-offset-background 
                    placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring 
                    focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          onChange={(e) => {
            if (e.target.value) {
              const attr = e.target.value as ProductAttribute;
              setSelectedAttribute(attr);
              setProductFilter({
                ...productFilter,
                [attr]: []
              });
              e.target.value = '';
            }
          }}
          value={selectedAttribute}
        >
          <option value="">+</option>
          {PRODUCT_ATTRIBUTES
            .filter(attr => !productFilter[attr])
            .map(attr => (
              <option key={attr} value={attr}>
                {attr}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

export default FilterBasic; 