import React from 'react';
import ReactECharts from 'echarts-for-react';
import { generateCustomPaletteColors } from '../../shared/colorUtils';
import { useFilterStore } from '../../stores/useFilterStore';
import { ProductAttribute } from '../../shared/types';

interface BarChartProps {
    data: any;
    attributeKey: ProductAttribute;
}

const BarChart: React.FC<BarChartProps> = ({ data, attributeKey }) => {
    const { addFilter } = useFilterStore();
    const xAxisData = data?.xAxis?.data || [];
    const yAxisValues = data?.series && data.series[0]?.data ? data.series[0].data : [];
    const colors = generateCustomPaletteColors(xAxisData.length);

    // Get the computed foreground color
    const getForegroundColor = () => {
        const root = document.documentElement;
        const foregroundHSL = getComputedStyle(root).getPropertyValue('--foreground').trim();
        return `hsl(${foregroundHSL})`;
    };

    const series = xAxisData.map((label: string, index: number) => ({
        name: label,
        type: 'bar',
        data: [yAxisValues[index] || 0],
        itemStyle: {
            color: colors[index % colors.length],
        },
    }));

    const chartOptions = {
        legend: {
            bottom: 0,
            padding: [5, 0],
            data: xAxisData,
            selectedMode: 'multiple',
            textStyle: {
                fontSize: 12,
                color: 'hsl(var(--foreground))',
            },
        },
        grid: {
            top: '10%',
            left: '3%',
            right: '4%',
            bottom: '30%',
            containLabel: true,
        },
        color: colors,
        xAxis: {
            type: 'category',
            axisLabel: {
                show: false,
                color: 'hsl(var(--foreground))',
            },
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                show: true,
                color: getForegroundColor()
            },
        },
        series: series,
        tooltip: {
            trigger: 'item',
            formatter: (params: any) => {
                return `${params.seriesName}: ${params.value}`;
            },
        },
    };

    // Double-click handler
    const onEvents = {
        dblclick: (params: any) => {
            if (params && params.name) {
                addFilter(attributeKey, params.seriesName);
            }
        },
    };

    return <ReactECharts option={chartOptions} style={{ height: 300 }} onEvents={onEvents} />;
};

export default BarChart;