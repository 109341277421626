import React from 'react';
import { useFilterStore } from '../stores/useFilterStore';
import { useDateRangeStore } from '../stores/useDateRangeStore';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui-kit/tabs"
import { Code } from "../ui-kit/code"
import { genApiCode } from "../shared/utils"
import { useUserDataStore } from '../stores/useUserDataStore';

const ApiCodeSamples: React.FC = () => {
  const languages = ["curl", "javascript", "bash", "python", "go", "rust", "ruby"];
  const filter = useFilterStore.getState().productFilter;
  const dateRange = useDateRangeStore.getState().dateRange;
  const apiKey = useUserDataStore.getState().userData.api_key;
  return (
    <Tabs defaultValue={languages[0]} className="w-full max-w-3xl">
      <TabsList className="mb-4">
        {languages.map((lang) => (
          <TabsTrigger key={lang} value={lang}>
            {lang}
          </TabsTrigger>
        ))}
      </TabsList>
      {languages.map((lang) => (
        <TabsContent key={lang} value={lang}>
          <Code language={lang}>{genApiCode(lang, filter, dateRange, apiKey)}</Code>
        </TabsContent>
      ))}
    </Tabs>
  );
};

export default ApiCodeSamples;
