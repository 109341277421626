import React, { useEffect, useState, useMemo } from 'react';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import { regionNameMap } from '../shared/constants';
import { generateCustomPaletteColors } from '../shared/colorUtils';
import ChartSelector from './ChartSelector';
import ChartsPageSelector from './ChartsPageSelector';
import { getChartData } from '../lib/chartUtils';
import PieChart from './charts/PieChart';
import BarChart from './charts/BarChart';
import HeatmapChart from './charts/HeatmapChart';
import TreemapChart from './charts/TreemapChart';
import FunnelChart from './charts/FunnelChart';
import { ProductAttribute } from '../shared/types';
import { useDarkMode } from '../hooks/useDarkMode';

const geoJsonUrl = 'https://raw.githubusercontent.com/datasets/geo-countries/master/data/countries.geojson';

interface ChartsPanelProps {
    attributeCounts: Record<string, Record<string, number>>;
}

const ChartsPanel: React.FC<ChartsPanelProps> = ({ attributeCounts }) => {
    const { isDarkMode } = useDarkMode();
    const themeColors = {
        textColor: isDarkMode ? '#e5e7eb' : '#1f2937',
        backgroundColor: isDarkMode ? '#1f2937' : '#ffffff',
        axisLineColor: isDarkMode ? '#4b5563' : '#d1d5db',
        tooltipBackground: isDarkMode ? 'rgba(17, 24, 39, 0.9)' : 'rgba(255, 255, 255, 0.9)',
        tooltipBorderColor: isDarkMode ? '#374151' : '#e5e7eb',
        mapDefaultColor: isDarkMode ? '#2d3748' : '#e2e8f0',
        mapHoverColor: isDarkMode ? '#ffffff' : '#ffffff',
        mapStrokeColor: isDarkMode ? '#4a5568' : '#cbd5e0',
    };

    const [currentPage, setCurrentPage] = useState<string>('Regional');
    const [chartsData, setChartsData] = useState<Record<string, any>>({});
    const [selectedChartTypes, setSelectedChartTypes] = useState<Record<string, string>>({});
    const [geoJsonData, setGeoJsonData] = useState<any>(null);
    const [tooltipContent, setTooltipContent] = useState<string | null>(null);
    const [totalAllCount, setTotalAllCount] = useState<number>(0);

    const pageAttributes: Record<string, ProductAttribute[]> = {
        Regional: ['region', 'industry', 'category', 'tags'],
        Source: ['source', 'author', 'reference', 'tags'],
        Indicators: ['category', 'tags', 'reference', 'indicators'],
    };

    const displayedAttributes = pageAttributes[currentPage] || [];
    const normalizeRegion = (region: string) => regionNameMap[region] || region;

    const handlePageChange = (page: string) => setCurrentPage(page);

    useEffect(() => {
        const initialSelectedChartTypes = Object.keys(attributeCounts).reduce((acc, key) => {
            acc[key] = 'pie';
            return acc;
        }, {} as Record<string, string>);
        setSelectedChartTypes(initialSelectedChartTypes);
    }, [attributeCounts]);

    useEffect(() => {
        const updateChartDataForAttribute = (attributeKey: string) => {
            const selectedChartType = selectedChartTypes[attributeKey];
            const attributeData = attributeCounts[attributeKey];

            if (selectedChartType && attributeData && Object.keys(attributeData).length > 0) {
                const chartData = getChartData(
                    selectedChartType,
                    attributeData,
                    `${selectedChartType.charAt(0).toUpperCase() + selectedChartType.slice(1)}`
                );

                setChartsData((prevChartsData) => ({
                    ...prevChartsData,
                    [attributeKey]: chartData,
                }));
            }
        };

        Object.keys(attributeCounts).forEach((attributeKey) => {
            if (!chartsData[attributeKey]) {
                updateChartDataForAttribute(attributeKey);
            }
        });
    }, [attributeCounts, chartsData]);

    useEffect(() => {
        const updatedChartData: Record<string, any> = {};

        Object.keys(attributeCounts).forEach((attributeKey) => {
            const selectedChartType = selectedChartTypes[attributeKey];
            const attributeData = attributeCounts[attributeKey];

            if (selectedChartType && attributeData && Object.keys(attributeData).length > 0) {
                updatedChartData[attributeKey] = getChartData(
                    selectedChartType,
                    attributeData,
                    `${selectedChartType.charAt(0).toUpperCase() + selectedChartType.slice(1)}`
                );
            }
        });

        setChartsData(updatedChartData);
    }, [attributeCounts, selectedChartTypes]);

    useEffect(() => {
        const initialSelectedChartTypes = Object.keys(attributeCounts).reduce((acc, key) => {
            acc[key] = selectedChartTypes[key] || 'pie';
            return acc;
        }, {} as Record<string, string>);

        setSelectedChartTypes(initialSelectedChartTypes);
    }, [attributeCounts]);

    useEffect(() => {
        setTotalAllCount(attributeCounts.region?.All || 0);
    }, [attributeCounts]);

    useEffect(() => {
        fetch(geoJsonUrl)
            .then(response => response.json())
            .then(data => setGeoJsonData(data))
            .catch(error => console.error('Failed to load GeoJSON:', error));
    }, []);

    const filteredRegionData = useMemo(() => {
        if (!geoJsonData) return [];
        return Object.keys(attributeCounts.region || {}).reduce((acc, region) => {
            if (region !== 'All') {
                const mappedName = normalizeRegion(region);
                const regionCount = attributeCounts.region[region] || 0;

                const matchedFeature = geoJsonData.features.find(
                    (feature: any) => feature.properties.ADMIN === mappedName
                );

                if (matchedFeature) {
                    acc.push({ name: mappedName, value: regionCount });
                } else {
                    console.warn(`No match in GeoJSON for "${region}" mapped as "${mappedName}"`);
                }
            }
            return acc;
        }, [] as { name: string; value: number }[]);
    }, [geoJsonData, attributeCounts]);

    const colors = generateCustomPaletteColors(filteredRegionData.length);
    const countryColors = filteredRegionData.reduce((acc, region, index) => {
        acc[region.name] = colors[index % colors.length];
        return acc;
    }, {} as Record<string, string>);

    const getCountryColor = (name: string) => countryColors[name] || themeColors.mapDefaultColor;

    const handleSelectChartType = (attributeKey: string, chartType: string) => {
        setSelectedChartTypes((prev) => ({
            ...prev,
            [attributeKey]: chartType,
        }));

        setChartsData((prevChartsData) => {
            const newChartsData = { ...prevChartsData };
            delete newChartsData[attributeKey];
            return newChartsData;
        });
    };

    return (
        <div className="bg-card p-4 rounded-md shadow-md space-y-8 relative">
            <ChartsPageSelector currentPage={currentPage} onPageChange={handlePageChange} />

            {displayedAttributes.includes('region') && (
                <div className="bg-background p-4 rounded-md shadow-md">
                    <h2 className="text-lg font-semibold mb-4 text-foreground">Region Distribution</h2>
                    <p className="text-foreground">Total All Count: {totalAllCount}</p>
                    <div style={{ height: '470px', overflow: 'hidden', position: 'relative' }}>
                        <ComposableMap 
                            projection="geoMercator" 
                            style={{ 
                                width: '100%', 
                                height: '100%',
                                backgroundColor: themeColors.backgroundColor 
                            }}
                        >
                            <ZoomableGroup>
                                {geoJsonData && (
                                    <Geographies geography={geoJsonData}>
                                        {({ geographies }) =>
                                            geographies.map(geo => {
                                                const countryName = geo.properties.ADMIN;
                                                const value = getCountryColor(countryName);

                                                return (
                                                    <Geography
                                                        key={geo.rsmKey}
                                                        geography={geo}
                                                        fill={value}
                                                        onMouseEnter={() => {
                                                            const countryData = filteredRegionData.find(region => region.name === countryName);
                                                            const displayValue = countryData ? countryData.value : 'No data';
                                                            setTooltipContent(`${countryName}: ${displayValue}`);
                                                        }}
                                                        onMouseLeave={() => {
                                                            setTooltipContent(null);
                                                        }}
                                                        style={{
                                                            default: {
                                                                outline: 'none',
                                                                stroke: themeColors.mapStrokeColor,
                                                                strokeWidth: 0.75,
                                                            },
                                                            hover: { 
                                                                fill: themeColors.mapHoverColor, 
                                                                outline: 'none',
                                                                stroke: themeColors.mapStrokeColor,
                                                                strokeWidth: 1,
                                                            },
                                                            pressed: { 
                                                                fill: themeColors.mapHoverColor, 
                                                                outline: 'none',
                                                                stroke: themeColors.mapStrokeColor,
                                                                strokeWidth: 1,
                                                            },
                                                        }}
                                                    />
                                                );
                                            })
                                        }
                                    </Geographies>
                                )}
                            </ZoomableGroup>
                        </ComposableMap>
                        {tooltipContent && (
                            <div style={{
                                position: 'absolute',
                                top: 10,
                                left: 10,
                                backgroundColor: themeColors.tooltipBackground,
                                color: themeColors.textColor,
                                padding: '5px 10px',
                                borderRadius: '1px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.15)'
                            }}>
                                {tooltipContent}
                            </div>
                        )}
                        <div style={{
                            position: 'absolute',
                            bottom: 1,
                            left: 1,
                            backgroundColor: themeColors.tooltipBackground,
                            color: themeColors.textColor,
                            padding: '10px',
                            borderRadius: '5px',
                            maxHeight: '150px',
                            overflowY: 'auto',
                            width: '200px',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
                        }}>
                            <h3 style={{ margin: '0 0 10px', fontSize: '14px', fontWeight: 'bold' }}>
                                Country Counts
                            </h3>
                            <ul style={{ listStyleType: 'none', padding: 0, margin: 0, fontSize: '12px' }}>
                                {filteredRegionData.map((country) => (
                                    <li key={country.name} style={{ marginBottom: '5px' }}>
                                        <strong>{country.name}:</strong> {country.value}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {displayedAttributes.map((attributeKey) => {
                    const chartData = chartsData[attributeKey];
                    const selectedChartType = selectedChartTypes[attributeKey];
                    const hasValidData = attributeCounts[attributeKey] &&
                        Object.values(attributeCounts[attributeKey] || {}).some((value) => value > 0);

                    return (
                        <div key={attributeKey} className="bg-background p-4 rounded-md shadow-md">
                            <h2 className="text-base font-semibold mb-4 text-foreground">{attributeKey}</h2>
                            <ChartSelector
                                selectedChart={selectedChartType}
                                onSelectChart={(chartType) => handleSelectChartType(attributeKey, chartType)}
                            />

                            {hasValidData && chartData ? (
                                <div className="mt-4">
                                    {selectedChartType === 'pie' && (
                                        <PieChart key={`pie-${attributeKey}`} data={chartData} attributeKey={attributeKey as ProductAttribute} />
                                    )}
                                    {selectedChartType === 'bar' && (
                                        <BarChart key={`bar-${attributeKey}`} data={chartData} attributeKey={attributeKey as ProductAttribute} />
                                    )}
                                    {selectedChartType === 'heatmap' && (
                                        <HeatmapChart key={`heatmap-${attributeKey}`} data={chartData} attributeKey={attributeKey as ProductAttribute} />
                                    )}
                                    {selectedChartType === 'treemap' && (
                                        <TreemapChart key={`treemap-${attributeKey}`} data={chartData} attributeKey={attributeKey as ProductAttribute} />
                                    )}
                                    {selectedChartType === 'funnel' && (
                                        <FunnelChart key={`funnel-${attributeKey}`} data={chartData} attributeKey={attributeKey as ProductAttribute} />
                                    )}
                                </div>
                            ) : (
                                <p className="text-center text-muted-foreground mt-4">
                                    No data available for this attribute.
                                </p>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ChartsPanel;