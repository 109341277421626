import React from 'react';

export const renderArrayItems = (items?: string | string[]): string => {
  if (Array.isArray(items)) {
    return items.join(', ');
  } else if (typeof items === 'string') {
    return items.replace(/^\[|\]$/g, '');
  } else {
    return 'N/A';
  }
};

export const renderReferences = (reference: string | string[] | undefined | boolean): React.ReactElement | string => {
  if (!reference || typeof reference === 'boolean') return 'N/A';
  const references = Array.isArray(reference) ? reference : reference.split(',');
  return (
    <ul className="list-disc pl-5">
      {references.map((ref, index) => (
        <li key={index}>
          <a 
            href={ref.trim()} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-blue-600 hover:underline"
          >
            {ref.trim()}
          </a>
        </li>
      ))}
    </ul>
  );
};

export const formatTimestamp = (timestamp: Date | undefined): string => {
  if (!timestamp) return 'N/A';
  return new Date(timestamp).toISOString().split('T')[0]; // This will return the date in yyyy-mm-dd format
};
