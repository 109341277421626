import * as React from "react"
import { addDays, format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { DateRange } from "react-day-picker"
import { cn } from "../lib/utils"
import { Button } from "../ui-kit/button"
import { Calendar } from "../ui-kit/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../ui-kit/popover"
import { useDateRangeStore } from "../stores/useDateRangeStore"
import { MAX_DATE_RANGE } from "../shared/constants"

interface DatePickerWithRangeProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  onChange?: (range: DateRange | undefined) => void;
}

export function DatePickerWithRange({
  className,
  onChange,
}: DatePickerWithRangeProps) {
  const today = new Date();
  const { dateRange, setDateRange } = useDateRangeStore();
  const [previousFromDate, setPreviousFromDate] = React.useState<Date | undefined>(dateRange?.from);
  const [tempDate, setTempDate] = React.useState<DateRange | undefined>(dateRange);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setTempDate(dateRange);
  }, [dateRange]);

  const handleSelect = (selectedRange: DateRange | undefined) => {
    if (!selectedRange) {
      setTempDate(undefined);
      return;
    }

    if (selectedRange.from && selectedRange.from === selectedRange.to) {
      setTempDate(selectedRange);
      setPreviousFromDate(selectedRange.from);
      return;
    }

    if (selectedRange?.from && selectedRange?.to) {
      if (selectedRange.to > today) {
        selectedRange.to = today;
      }
      
      const dayDifference = Math.ceil((selectedRange.to.getTime() - selectedRange.from.getTime()) / (1000 * 60 * 60 * 24));
      
      if (dayDifference > MAX_DATE_RANGE) {
        if (selectedRange.from === previousFromDate) {
          selectedRange.from = addDays(selectedRange.to, -MAX_DATE_RANGE);
        } else {
          selectedRange.to = addDays(selectedRange.from, MAX_DATE_RANGE);
        }
      }
    }
    setPreviousFromDate(selectedRange.from);
    setTempDate(selectedRange);
  };

  const handleOK = () => {
    setOpen(false);
    if (tempDate?.from && tempDate?.to) {
      setDateRange({
        from: tempDate.from,
        to: tempDate.to
      });
    }
    if (onChange) {
      onChange(tempDate);
    }
  };

  const handleClose = () => {
    setTempDate(dateRange);
    setOpen(false);
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[calc(100%-0.8rem)] mx-1 justify-start text-left font-normal",
              !dateRange && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {dateRange?.from ? (
              dateRange.to ? (
                <>
                  {format(dateRange.from, "LLL dd, y")} -{" "}
                  {format(dateRange.to, "LLL dd, y")}
                </>
              ) : (
                format(dateRange.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent 
          className="w-auto p-0 relative" 
          align="start"
          side="right"
          sideOffset={10}
          style={{ maxHeight: 'calc(100vh - 100px)', overflowY: 'auto' }}
        >
          <Calendar
            mode="range"
            defaultMonth={tempDate?.from}
            selected={tempDate}
            onSelect={handleSelect}
            numberOfMonths={2}
            disabled={{ from: addDays(today, 1) }}
            className="rounded-md border"
            toDate={today}
          />
          <div className="flex justify-end gap-2 p-2">
            <Button variant="outline" onClick={handleClose}>Close</Button>
            <Button onClick={handleOK}>OK</Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
