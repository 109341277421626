import * as React from "react";
import { BarChart3, Database, ChevronsUpDown, User2, LayoutDashboard, ChevronDown, LogOut, BadgeCheck, Settings, MessageCircleCode, CreditCard, BookmarkIcon } from "lucide-react";
import Logo from '../images/Truss_Logo.png';
import { 
  Sidebar, 
  SidebarContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel
} from "../ui-kit/sidebar";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "../ui-kit/dropdown-menu";
import AppSidebarFilter from './AppSidebarFilter';
import { DatePickerWithRange } from './DatePickerWithRange';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../ui-kit/collapsible";
import { Link, useLocation } from 'react-router-dom';
import { useUserDataStore } from "../stores/useUserDataStore";

export function AppSidebar() {
  const location = useLocation();
  const { userData, fetchUserData } = useUserDataStore();
  const showFilters = ['/charts', '/products', '/api'].includes(location.pathname);

  React.useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <Sidebar>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton asChild>
              <Link to="/insights">
                <img src={Logo} alt="Truss Logo" className="w-7 h-6 mr-2" />
                Truss
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>

      <SidebarContent>
        <div className="space-y-1">
          <SidebarMenuButton tooltip="Insights" asChild>
            <Link to="/insights">
              <LayoutDashboard />
              <span>Insights</span>
            </Link>
          </SidebarMenuButton>
          <SidebarMenuButton tooltip="Query Builder" asChild>
            <Link to="/products">
              <Database />
              <span>Products</span>
            </Link>
          </SidebarMenuButton>
          <SidebarMenuButton tooltip="Charts" asChild>
            <Link to="/charts">
              <BarChart3 />
              <span>Charts</span>
            </Link>
          </SidebarMenuButton>
          <SidebarMenuButton tooltip="API" asChild>
            <Link to="/api">
              <MessageCircleCode />
              <span>API</span>
            </Link>
          </SidebarMenuButton>
        </div>
        {showFilters && (
          <>
            <DatePickerWithRange />
            <Collapsible defaultOpen className="group/collapsible mt-1">
              <SidebarGroup>
                <SidebarGroupLabel asChild>
                  <CollapsibleTrigger>
                    Filter
                    <ChevronDown className="ml-auto transition-transform group-data-[state=closed]/collapsible:-rotate-90" />
                  </CollapsibleTrigger>
                </SidebarGroupLabel>
                <CollapsibleContent>
                  <SidebarGroupContent>
                    <AppSidebarFilter />
                  </SidebarGroupContent>
                </CollapsibleContent>
              </SidebarGroup>
            </Collapsible>
          </>
        )}
      </SidebarContent>

      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <SidebarMenuButton>
                    <User2 /> {userData.username || 'Guest'}
                    <ChevronsUpDown className="ml-auto" />
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  side="top"
                  className="w-[--radix-popper-anchor-width]"
                >
                  <DropdownMenuItem>
                    <SidebarMenuButton tooltip="Account" asChild>
                      <Link to="/my-account">
                        <BadgeCheck />
                        <span>Account</span>
                      </Link>
                    </SidebarMenuButton>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <SidebarMenuButton tooltip="Billing" asChild>
                      <Link to="/billing">
                        <CreditCard />
                        <span>Billing</span>
                      </Link>
                    </SidebarMenuButton>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <SidebarMenuButton tooltip="Resources" asChild>
                      <Link to="/resources">
                        <Settings />
                        <span>Resources</span>
                      </Link>
                    </SidebarMenuButton>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <SidebarMenuButton tooltip="Saved Filters" asChild>
                      <Link to="/saved-filters">
                        <BookmarkIcon />
                        <span>Saved Filters</span>
                      </Link>
                    </SidebarMenuButton>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>
                    <SidebarMenuButton tooltip="Sign out" asChild>
                      <Link to="/signout">
                        <LogOut />
                        <span>Log out</span>
                      </Link>
                    </SidebarMenuButton>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>

    </Sidebar>
  );
}