import { create } from 'zustand'
import { ProductAttribute, ProductFilter } from '../shared/types';

const initialFilter: ProductFilter = {}

interface FilterStore {
  productFilter: ProductFilter;
  setProductFilter: (filter: ProductFilter) => void;
  addFilter: (key: ProductAttribute, value: string) => void;
  removeFilter: (key: ProductAttribute, value: string) => void;
  removeFilterGroup: (key: ProductAttribute) => void;
}

export const useFilterStore = create<FilterStore>((set) => ({
  productFilter: initialFilter,
  setProductFilter: (filter) => set({ productFilter: filter }),
  addFilter: (key, value) => set((state) => {
    const currentValues = state.productFilter[key] || [];
    if (!currentValues.includes(value)) {
      return {
        productFilter: {
          ...state.productFilter,
          [key]: [...currentValues, value],
        },
      };
    }
    return state;
  }),
  removeFilter: (key, value) => set((state) => {
    const currentValues = state.productFilter[key] || [];
    const newValues = currentValues.filter(v => v !== value);
    if (newValues.length === 0) {
      return {
        productFilter: Object.fromEntries(
          Object.entries(state.productFilter).filter(([k]) => k !== key)
        ),
      };
    }
    return {
      productFilter: {
        ...state.productFilter,
        [key]: newValues,
      },
    };
  }),
  removeFilterGroup: (key) => set((state) => ({
    productFilter: Object.fromEntries(
      Object.entries(state.productFilter).filter(([k]) => k !== key)
    ),
  })),
}))