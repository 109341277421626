// Indicators have unique queries

/**
 * Get the total count of all indicators
 * @returns {number} - The total count of indicators.
 * @example
 * const count: number = await db.query(INDICATORS_COUNT_QUERY);
 */
export const INDICATORS_COUNT_QUERY = `
  SELECT COUNT(*) as count 
  FROM product_indicators 
  WHERE timestamp >= NOW() - INTERVAL '7 days'
`;
/**
 * Get the count of products for each indicator
 * @param {number[]} productIds - The IDs of the products.
 * @param {number} days - The number of days to look back.
 * @returns {PgIndicator[]} - The indicators matching the criteria.
 * @example
 * const indicators: PgIndicator[] = await db.query(INDICATOR_NAME_COUNT_QUERY, [1, 2, 3], 7);
 */
export const PRODUCT_INDICATORS_QUERY = `
  SELECT pi.product_id, i.name as indicator_name, pi.value, pi.timestamp
  FROM product_indicators pi
  JOIN indicators i ON pi.indicator_id = i.id
  WHERE pi.product_id = ANY($1)
    AND pi.timestamp >= NOW() - (($2 || ' days')::INTERVAL)
  ORDER BY pi.product_id, i.name, pi.timestamp DESC
  LIMIT 1000
`;

/**
 * Get indicators for a range of product IDs without time filtering
 * @param {number} startProductId - The starting product ID of the range.
 * @param {number} endProductId - The ending product ID of the range.
 * @returns {PgIndicator[]} - The indicators matching the criteria.
 * @example
 * const indicators: PgIndicator[] = await db.query(PRODUCT_INDICATORS_RANGE_QUERY, [100, 200]);
 */
export const PRODUCT_INDICATORS_RANGE_QUERY = `
  SELECT pi.product_id, i.name as indicator_name, pi.value, pi.timestamp
  FROM product_indicators pi
  JOIN indicators i ON pi.indicator_id = i.id
  WHERE pi.product_id >= $1 AND pi.product_id <= $2
  ORDER BY pi.product_id, i.name, pi.timestamp DESC
  LIMIT 1000
`;

/**
 * Get the count of products for each indicator
 * @param {number[]} productIds - The IDs of the products.
 * @param {number} days - The number of days to look back.
 * @returns {PgIndicator[]} - The indicators matching the criteria.
 * @example
 * const indicators: PgIndicator[] = await db.query(INDICATOR_NAME_COUNT_QUERY, [1, 2, 3], 7);
 */
export const INDICATOR_NAME_COUNT_QUERY = `
  SELECT i.name AS name, CAST(COUNT(DISTINCT pi.product_id) AS INTEGER) AS count
  FROM product_indicators pi
  JOIN indicators i ON pi.indicator_id = i.id
  WHERE pi.product_id = ANY($1)
    AND pi.timestamp >= NOW() - (($2 || ' days')::INTERVAL)
  GROUP BY i.name
  ORDER BY count DESC, name ASC
  LIMIT 1000
`;

  /**
 * Get the count of products for each indicator
 * @param {number[]} productIds - The IDs of the products.
 * @param {number} days - The number of days to look back.
 * @returns {PgIndicator[]} - The indicators matching the criteria.
 * @example
 * const indicators: PgIndicator[] = await db.query(INDICATOR_NAME_COUNT_QUERY, [1, 2, 3], 7);
 */
export const INDICATOR_VALUE_COUNT_QUERY = `
  SELECT pi.indicator_name AS indicator, pi.value AS item, CAST(COUNT(DISTINCT pi.product_id) AS INTEGER) AS count
  FROM product_indicators pi
  WHERE pi.product_id = ANY($1)
    AND pi.timestamp >= NOW() - (($2 || ' days')::INTERVAL)
  GROUP BY pi.indicator_name, pi.value
  ORDER BY count DESC, indicator ASC, item ASC
  LIMIT 1000
`;