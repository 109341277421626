import { supabaseClient } from '../services/supabase';

class AuthService {
    async login(email: string, password: string) {
        const { data, error } = await supabaseClient.auth.signInWithPassword({
            email,
            password
        });
        
        if (error) throw error;
        return data.user;
    }

    async logout() {
        const { error } = await supabaseClient.auth.signOut();
        if (error) throw error;
    }

    async checkAuthStatus() {
        const { data: { session } } = await supabaseClient.auth.getSession();
        
        if (session) {
            // Check if session has expired
            const currentTime = Math.floor(Date.now() / 1000);
            if (session.expires_at && session.expires_at < currentTime) {
                await this.logout();
                return false;
            }
            return true;
        }
        return false;
    }
}

export default new AuthService();