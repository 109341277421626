import React from 'react';
import { SavedFiltersManager } from '../components/SavedFiltersManager';

const SavedFiltersPage: React.FC = () => {
  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold tracking-tight">Saved Filters</h1>
      <SavedFiltersManager />
    </div>
  );
};

export default SavedFiltersPage; 