import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { signUpUser, claimAccessCode, checkAccessCode } from '../services/supabase';
import { Input } from '../ui-kit/input';

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const { login, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const [accessCode, setAccessCode] = useState('');

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/insights');
        }
    }, [isAuthenticated, navigate]);

    const validateInputs = () => {
        if (!email || !password) {
            setError('Email and password are required.');
            return false;
        }
        return true;
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateInputs()) return;

        setIsLoading(true);
        setError('');
        try {
            await login(email, password);
            navigate('/insights');
        } catch (err: any) {
            console.error('Login failed:', err);
            setError(err.message || 'Login failed. Please check your email and password.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSignUp = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateInputs()) return;
        if (!accessCode) {
            setError('Access code is required for registration.');
            return;
        }

        setIsLoading(true);
        setError('');
        setMessage('');
        
        try {
            const accessCodeData = await checkAccessCode(accessCode);
            if (!accessCodeData) {
                throw new Error('Invalid or already claimed access code.');
            }
            if (accessCodeData.claimed) {
                throw new Error('This access code has already been claimed.');
            }

            await signUpUser(email, password, accessCode);
            
            await claimAccessCode(accessCode, email);
            
            setMessage('Check your email for the confirmation link!');
            setEmail('');
            setPassword('');
            setAccessCode('');
        } catch (err: any) {
            console.error('Sign up failed:', err);
            setError(err.message || 'Sign up failed. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex min-h-screen p-8 md:p-12 lg:p-16 bg-background">
            {/* Left Panel */}
            <div className="hidden lg:flex lg:w-1/2 bg-white items-center justify-center p-12 rounded-l-2xl shadow-lg">
                <div className="max-w-lg">
                    <img
                        src={`${process.env.PUBLIC_URL}/Truss_Logo.png`}
                        alt="Truss Logo"
                        className="w-full h-auto"
                    />
                </div>
            </div>

            {/* Right Panel - Login Form */}
            <div className="w-full lg:w-1/2 flex items-center justify-center bg-white p-8 rounded-2xl lg:rounded-l-none shadow-lg">
                <div className="w-full max-w-md space-y-8">
                    {/* Show logo only on mobile */}
                    <div className="lg:hidden text-center mb-8">
                        <img
                            src={`${process.env.PUBLIC_URL}/Truss_Logo.png`}
                            alt="Truss Logo"
                            className="mx-auto h-16 w-auto"
                        />
                    </div>

                    <div className="text-center">
                        <h2 className="text-2xl font-semibold text-foreground">
                            {isRegistering ? 'Create your account' : 'Welcome back'}
                        </h2>
                        <p className="mt-2 text-sm text-muted-foreground">
                            {isRegistering 
                                ? 'Sign up to get started' 
                                : 'Please sign in to your account'}
                        </p>
                    </div>

                    <div className="text-center">
                        <button
                            onClick={() => {
                                setIsRegistering(!isRegistering);
                                setError('');
                                setMessage('');
                            }}
                            className="text-sm text-primary hover:text-primary/80"
                        >
                            {isRegistering ? 'Already have an account? Login' : 'Need an account? Sign Up'}
                        </button>
                    </div>

                    {message && (
                        <div className="p-3 text-sm bg-emerald-50 text-emerald-700 rounded-md">
                            {message}
                        </div>
                    )}

                    <form onSubmit={isRegistering ? handleSignUp : handleLogin} className="space-y-4">
                        <div className="space-y-2">
                            <label className="text-sm font-medium text-foreground">Email</label>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="space-y-2">
                            <label className="text-sm font-medium text-foreground">Password</label>
                            <Input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        {isRegistering && (
                            <div className="space-y-2">
                                <label className="text-sm font-medium text-foreground">Access Code</label>
                                <Input
                                    type="text"
                                    value={accessCode}
                                    onChange={(e) => setAccessCode(e.target.value)}
                                    required={isRegistering}
                                    placeholder="Enter your access code"
                                />
                            </div>
                        )}
                        {error && <p className="text-destructive text-sm">{error}</p>}
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={`w-full py-2 px-4 rounded-md text-primary-foreground transition-colors ${
                                isLoading ? 'bg-muted cursor-not-allowed' : 'bg-primary hover:bg-primary/90'
                            }`}
                        >
                            {isLoading 
                                ? (isRegistering ? 'Signing up...' : 'Logging in...') 
                                : (isRegistering ? 'Sign Up' : 'Login')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;