import React, { useEffect, useState, useCallback, useRef } from 'react';

import { useProductIdStore } from '../stores/useProductIdStore';
import { usePagination } from '../hooks/usePagination'; // New custom hook

import ProductCard from './ProductCard';
import { PgProduct } from '../shared/types';
import { getProducts } from '../services/pgService';

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '../ui-kit/pagination';


const ProductList: React.FC = () => {
  const { count, productIdPages, currentPage, fetchProductIds, isLoading } = useProductIdStore();
  const [data, setData] = useState<PgProduct[]>([]);
  const productsPerPage = 20;
  const totalPages = Math.ceil(count / productsPerPage);
  const initialFetchDone = useRef(false);

  const fetchPage = useCallback((page: number) => {
    if (!productIdPages[page] && !isLoading) {
      fetchProductIds(page);
    }
  }, [fetchProductIds, productIdPages, isLoading]);

  const {
    currentPage: paginationCurrentPage,
    totalPages: paginationTotalPages,
    pageNumbers,
    goToPage,
    goToPreviousPage,
    goToNextPage
  } = usePagination(totalPages, currentPage, fetchPage);

  useEffect(() => {
    if (!initialFetchDone.current) {
      fetchPage(currentPage);
      initialFetchDone.current = true;
    }
  }, [fetchPage, currentPage]);

  useEffect(() => {
    if (productIdPages[currentPage]) {
      const ids = productIdPages[currentPage].map(item => item.id);
      getProducts(ids, 1, productsPerPage).then(({ products }) => setData(products));
    }
  }, [productIdPages, currentPage]);

  return (
    <div className="flex flex-col h-full">
      <div className="sticky top-0 z-10 bg-background border-b border-border py-2 mb-4">
        <h2 className="text-lg font-semibold text-foreground text-center">
          Total Products: {count}
        </h2>
      </div>
      <PaginationComponent
        currentPage={paginationCurrentPage}
        totalPages={paginationTotalPages}
        pageNumbers={pageNumbers}
        goToPage={goToPage}
        goToPreviousPage={goToPreviousPage}
        goToNextPage={goToNextPage}
      />
      {isLoading ? (
        <div className="text-center text-muted-foreground">Loading...</div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {data.map((product: PgProduct) => (
            <ProductCard
              key={product.id}
              product={product}
            />
          ))}
        </div>
      )}
      <div className="mt-auto">
        <PaginationComponent
          currentPage={paginationCurrentPage}
          totalPages={paginationTotalPages}
          pageNumbers={pageNumbers}
          goToPage={goToPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
        />
      </div>
    </div>
  );
};

const PaginationComponent = ({
  currentPage,
  totalPages,
  pageNumbers,
  goToPage,
  goToPreviousPage,
  goToNextPage
}) => (
  <Pagination className="my-4">
    <PaginationContent>
      <PaginationItem>
        <PaginationPrevious 
          onClick={goToPreviousPage}
          className={currentPage === 1 ? 'pointer-events-none opacity-50' : ''}
        />
      </PaginationItem>
      {pageNumbers.map((pageNumber, index) => (
        <PaginationItem key={index}>
          {pageNumber === 'ellipsis' ? (
            <PaginationEllipsis />
          ) : (
            <PaginationLink
              onClick={() => goToPage(Number(pageNumber))}
              isActive={currentPage === pageNumber}
            >
              {pageNumber}
            </PaginationLink>
          )}
        </PaginationItem>
      ))}
      <PaginationItem>
        <PaginationNext 
          onClick={goToNextPage}
          className={currentPage === totalPages ? 'pointer-events-none opacity-50' : ''}
        />
      </PaginationItem>
    </PaginationContent>
  </Pagination>
);

export default ProductList;
