import React, { useRef, useEffect } from 'react';
import { PgProduct } from '../shared/types';
import { Card, CardHeader, CardTitle, CardContent } from '../ui-kit/card';
import { renderArrayItems, renderReferences, formatTimestamp } from '../shared/formatters';

interface ProductCardModalProps {
  product: PgProduct;
  isOpen: boolean;
  onClose: () => void;
}

const ProductCardModal: React.FC<ProductCardModalProps> = ({ product, isOpen, onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center p-4 z-[100]">
      <Card ref={modalRef} className="w-full max-w-4xl max-h-[90vh] overflow-y-auto relative text-sm">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-muted-foreground hover:text-foreground text-xl font-bold"
          aria-label="Close"
        >
          &times;
        </button>
        <CardHeader>
          <CardTitle className="text-lg text-foreground">{product.title}</CardTitle>
        </CardHeader>
        <CardContent className="space-y-3">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p><span className="font-semibold text-foreground">Source:</span> <span className="text-muted-foreground">{product.source || 'N/A'}</span></p>
              <p><span className="font-semibold text-foreground">Category:</span> <span className="text-muted-foreground">{product.category || 'N/A'}</span></p>
              <p><span className="font-semibold text-foreground">Publication Date:</span> <span className="text-muted-foreground">{formatTimestamp(product.pub_date)}</span></p>
              <p><span className="font-semibold text-foreground">Ingestion Date:</span> <span className="text-muted-foreground">{formatTimestamp(product.timestamp)}</span></p>
            </div>
            <div>
              <p><span className="font-semibold text-foreground">Authors:</span> <span className="text-muted-foreground">{renderArrayItems(product.author)}</span></p>
              <p><span className="font-semibold text-foreground">Product ID:</span> <span className="text-muted-foreground">{product.id || 'N/A'}</span></p>
              <p><span className="font-semibold text-foreground">Type:</span> <span className="text-muted-foreground">{product.type || 'N/A'}</span></p>
            </div>
          </div>
          {product.chainTxn && product.chainTxn.transaction_id && (
            <p>
              <span className="font-semibold text-foreground">Blockchain Transaction: </span>
              <a href={`https://hashscan.io/testnet/transaction/${product.chainTxn.transaction_id}`} 
                target='_blank' 
                rel='noopener noreferrer' 
                className="text-primary hover:text-primary/90 hover:underline">
                {product.chainTxn.transaction_id}
              </a>
            </p>
          )}
          <div>
            <p><span className="font-semibold text-foreground">Region:</span></p>
            <span className="text-muted-foreground">{renderArrayItems(product.region)}</span>
            <p><span className="font-semibold text-foreground">Industry:</span></p>
            <span className="text-muted-foreground">{renderArrayItems(product.industry)}</span>
          </div>
          <div>
            <p className="font-semibold text-foreground">Reference:</p>
            <span className="text-muted-foreground">{renderReferences(product.reference)}</span>
          </div>
          <div>
            <p><span className="font-semibold text-foreground">Tags:</span> <span className="text-muted-foreground">{renderArrayItems(product.tags)}</span></p>
          </div>
          {product.indicators && typeof product.indicators === 'object' && (
            <div>
              <p className="font-semibold text-foreground">Indicators:</p>
              <pre className="bg-muted p-2 rounded overflow-x-auto">
                <code className="text-muted-foreground">{JSON.stringify(product.indicators, null, 2)}</code>
              </pre>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default ProductCardModal;
