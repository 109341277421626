// src/lib/chartUtils.ts
import {
    createPieChartData,
    createBarChartData,
    createHeatmapChartData,
    createTreemapChartData,
    createFunnelChartData,
} from '../stores/eChartStore';

export const getChartData = (
    type: string,
    data: Record<string, number>,
    title: string
) => {
    switch (type) {
        case 'pie':
            return createPieChartData(data, title);
        case 'bar':
            return createBarChartData(data, title);
        case 'heatmap':
            return createHeatmapChartData(data, title);
        case 'treemap':
            return createTreemapChartData(data, title);
        case 'funnel':
            return createFunnelChartData(data, title);
        default:
            return null;
    }
};