import { interpolateRgb } from 'd3-interpolate';

// Custom palette ordered from left to right as they appear in the image
const customPalette = ['#095394', '#4E8ECC', '#BCBEC0'];

/**
 * Generate an expanded palette with at least two steps between each base color.
 * @param itemCount - Number of items to generate colors for.
 * @returns Array of color strings.
 */
export const generateCustomPaletteColors = (itemCount: number) => {
    // Array to hold expanded colors
    const expandedPalette: string[] = [];

    // Add intermediate steps between each pair of colors
    for (let i = 0; i < customPalette.length - 1; i++) {
        const startColor = customPalette[i];
        const endColor = customPalette[i + 1];
        const colorInterpolator = interpolateRgb(startColor, endColor);

        // Push start color and two interpolated colors
        expandedPalette.push(startColor);
        expandedPalette.push(colorInterpolator(0.5)); // Midpoint color
        expandedPalette.push(endColor);
    }

    // If itemCount is less than expandedPalette length, take a subset
    if (itemCount <= expandedPalette.length) {
        const interval = Math.floor(expandedPalette.length / itemCount);
        return Array.from({ length: itemCount }, (_, i) => expandedPalette[i * interval]);
    }

    // If itemCount exceeds expandedPalette length, repeat colors as necessary
    return Array.from({ length: itemCount }, (_, i) => expandedPalette[i % expandedPalette.length]);
};