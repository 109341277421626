import React, { useState } from 'react';
import { PgProduct } from '../shared/types';
import { Card, CardHeader, CardTitle, CardContent } from '../ui-kit/card';
import { renderArrayItems, formatTimestamp } from '../shared/formatters';
import ProductCardModal from './ProductCardModal';

interface ProductCardProps {
  product: PgProduct;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Card 
        className="w-full max-w-lg text-xs cursor-pointer hover:shadow-lg hover:border-blue-200 transition-all"
        onClick={() => setIsModalOpen(true)}
      >
        <CardHeader>
          <CardTitle className="text-base">{product.title}</CardTitle>
        </CardHeader>
        <CardContent className="space-y-3 text-xs">
          <div className="grid grid-cols-4 gap-3">
            <div className="col-span-2">
              <p><span className="font-semibold">Source:</span> {product.source || 'N/A'}</p>
              <p><span className="font-semibold">Author:</span> {renderArrayItems(product.author)}</p>
            </div>
            <div className="col-span-2">
              <p><span className="font-semibold">Publication Date:</span> {formatTimestamp(product.pub_date)}</p>
              <p><span className="font-semibold">Category:</span> {product.category || 'N/A'}</p>
            </div>
          </div>
          <div>
            <span className="font-semibold">Region:</span> {renderArrayItems(product.region)}<br />
            <span className="font-semibold">Industry:</span> {renderArrayItems(product.industry)}
          </div>
        </CardContent>
      </Card>
      <ProductCardModal
        product={product}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default ProductCard;
