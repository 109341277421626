import { create } from 'zustand';
import { TrussUsers, fetchUserDataFromSupabase, updateUserDataInSupabase } from '../services/supabase';

// Define the shape of the userData that we'll use in the store
interface UserDataStore {
    // State
    userData: TrussUsers;
    isLoading: boolean;
    error: string | null;

    // Actions
    fetchUserData: () => Promise<void>;
    updateUserData: (updates: Partial<TrussUsers>) => Promise<void>;
    resetUserData: () => void;
}

const initialUserData: TrussUsers = {
    id: '', 
    username: '',
    bio: '',
    api_key: '',
    avatar_url: '',
    role: 'free',
    filters: [],
    charts: [],
    subscriptions: [],
    liked_authors: [],
    liked_products: [],
    liked_sources: [],
};

export const useUserDataStore = create<UserDataStore>((set) => ({
    // Initial state
    userData: initialUserData,
    isLoading: false,
    error: null,

    // Actions
    fetchUserData: async () => {
        set({ isLoading: true, error: null });
        try {
            const userData = await fetchUserDataFromSupabase();
            set({ 
                userData: {
                    ...initialUserData,
                    ...userData,
                }, 
                isLoading: false 
            });
        } catch (error: any) {
            console.error('Detailed error:', error);
            set({ 
                error: error.message, 
                isLoading: false 
            });
        }
    },

    updateUserData: async (updates: Partial<TrussUsers>) => {
        set({ isLoading: true, error: null });
        try {
            await updateUserDataInSupabase(updates);
            set({ isLoading: false });
        } catch (error: any) {
            set({ 
                error: error.message, 
                isLoading: false 
            });
            throw error;
        }
    },

    resetUserData: () => {
        set({ 
            userData: initialUserData,
            error: null,
            isLoading: false
        });
    }
}));
