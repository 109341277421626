import React, { useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import { generateCustomPaletteColors } from '../../shared/colorUtils';
import { useFilterStore } from '../../stores/useFilterStore';
import { useDarkMode } from '../../hooks/useDarkMode';

type ProductAttribute =
    | "category"
    | "region"
    | "industry"
    | "source"
    | "tags"
    | "author";

interface StackedAreaChartProps {
    title: string;
    data: { [key: string]: any }[];
    xKey: string;
    yKeys: string[];
    itemKey: ProductAttribute;
}

const mapItemKeyToProductAttribute = (key: string): ProductAttribute => {
    switch (key) {
        case "category":
            return "category";
        case "region":
            return "region";
        case "industry":
            return "industry";
        case "source":
            return "source";
        case "tag":
        case "tags": // Handle both singular and plural forms of Tags
            return "tags";
        case "author":
            return "author";
        default:
            throw new Error(`Unsupported itemKey: ${key}`);
    }
};

const StackedAreaChart: React.FC<StackedAreaChartProps> = ({
    title,
    data,
    xKey,
    yKeys,
    itemKey,
}) => {
    const { isDarkMode } = useDarkMode();
    const themeColors = {
        textColor: isDarkMode ? '#e5e7eb' : '#1f2937',
        backgroundColor: isDarkMode ? '#1f2937' : '#ffffff',
        axisLineColor: isDarkMode ? '#4b5563' : '#d1d5db',
        tooltipBackground: isDarkMode ? 'rgba(17, 24, 39, 0.9)' : 'rgba(255, 255, 255, 0.9)',
        tooltipBorderColor: isDarkMode ? '#374151' : '#e5e7eb',
    };
    const colors = generateCustomPaletteColors(yKeys.length);
    const chartRef = useRef<any>(null);
    const { addFilter } = useFilterStore();

    // Function to calculate percentages based on visible series
    const calculatePercentages = (visibleKeys: string[]) => {
        return data.map((dayData) => {
            const total = visibleKeys.reduce((sum, key) => sum + (dayData[key] || 0), 0); // Sum visible series
            const percentages = visibleKeys.reduce((acc, key) => {
                acc[key] = total > 0 ? ((dayData[key] || 0) / total) * 100 : 0;
                return acc;
            }, {} as { [key: string]: number });
            return {
                ...dayData,
                ...percentages,
            };
        });
    };

    let visibleKeys = [...yKeys];
    let normalizedData = calculatePercentages(visibleKeys);

    const series = visibleKeys.map((key, index) => ({
        name: key,
        type: 'line',
        stack: 'total',
        areaStyle: {},
        emphasis: {
            focus: 'series',
            label: {
                show: true,
                position: 'top',
                formatter: ({ value, dataIndex }: any) => {
                    const totalForDay = visibleKeys.reduce(
                        (sum, seriesKey) => sum + (normalizedData[dataIndex]?.[seriesKey] || 0),
                        0
                    );
                    const percentage = totalForDay > 0 ? (value / totalForDay) * 100 : 0;
                    return `${key}: ${percentage.toFixed(2)}%`;
                },
            },
        },
        smooth: true,
        data: normalizedData.map((item) => item[key] || 0),
        itemStyle: {
            color: colors[index % colors.length],
        },
        symbol: 'circle',
        symbolSize: 8,
        label: {
            show: false,
            position: 'top',
            formatter: ({ value, dataIndex }: any) => {
                const totalForDay = visibleKeys.reduce(
                    (sum, seriesKey) => sum + (normalizedData[dataIndex]?.[seriesKey] || 0),
                    0
                );
                const percentage = totalForDay > 0 ? (value / totalForDay) * 100 : 0;
                const date = normalizedData[dataIndex]?.[xKey];
                return `${key} (${date}): ${percentage.toFixed(2)}%`;
            },
        },
    }));

    const chartOptions = {
        title: {
            text: title,
            left: 'center',
            textStyle: { 
                fontSize: 16,
                color: themeColors.textColor 
            },
        },
        tooltip: { 
            show: false,
            backgroundColor: themeColors.tooltipBackground,
            borderColor: themeColors.tooltipBorderColor,
            textStyle: {
                color: themeColors.textColor
            }
        },
        legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 10,
            top: 'middle',
            itemWidth: 14,
            itemHeight: 14,
            textStyle: { 
                fontSize: 12,
                color: themeColors.textColor 
            },
            padding: [5, 10],
            formatter: function(name: string) {
                const maxLength = 20;
                return name.length > maxLength ? name.slice(0, maxLength) + '...' : name;
            }
        },
        color: colors,
        grid: {
            left: '4%',
            right: '20%',
            bottom: '4%',
            top: '10%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            data: normalizedData.map((item) => item[xKey]),
            boundaryGap: true,
            axisLabel: {
                formatter: (value: string) => {
                    const date = new Date(value);
                    return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
                },
                rotate: 45,
                color: themeColors.textColor
            },
            axisLine: { 
                onZero: true,
                lineStyle: {
                    color: themeColors.axisLineColor
                }
            },
            splitLine: {
                lineStyle: {
                    color: themeColors.axisLineColor
                }
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: { 
                formatter: '{value}%',
                color: themeColors.textColor
            },
            max: 100,
            axisLine: {
                lineStyle: {
                    color: themeColors.axisLineColor
                }
            },
            splitLine: {
                lineStyle: {
                    color: themeColors.axisLineColor
                }
            }
        },
        series: series,
        backgroundColor: themeColors.backgroundColor
    };

    const onEvents = {
        dblclick: (params: any) => {
            if (params && params.seriesName) {
                const value = params.seriesName;
                const productAttribute = mapItemKeyToProductAttribute(itemKey); // Convert to ProductAttribute
                console.log(`Double clicked: ${productAttribute} = ${value}`);
                addFilter(productAttribute, params.seriesName); // Use the mapped value
                console.log(`Added ${productAttribute}:${params.seriesName} to filters`);
            }
        },
        legendselectchanged: (event: any) => {
            visibleKeys = Object.keys(event.selected).filter((key) => event.selected[key]);
            normalizedData = calculatePercentages(visibleKeys);

            const updatedSeries = visibleKeys.map((key) => ({
                name: key,
                type: 'line',
                stack: 'total',
                areaStyle: {},
                smooth: true,
                data: normalizedData.map((item) => item[key] || 0),
                itemStyle: { color: colors[yKeys.indexOf(key) % colors.length] },
            }));

            const chartInstance = chartRef.current.getEchartsInstance();
            chartInstance.setOption({
                series: updatedSeries,
                xAxis: { data: normalizedData.map((item) => item[xKey]) },
            });
        },
    };

    return (
        <div className="p-4 bg-card shadow rounded">
            <ReactECharts
                ref={chartRef}
                option={chartOptions}
                style={{ height: 500, width: '100%' }}
                onEvents={onEvents}
                theme={isDarkMode ? 'dark' : undefined}
            />
        </div>
    );
};

export default StackedAreaChart;