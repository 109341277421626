import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL!
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY!

export type TrussUsers = {
  id: string;
  api_key: string;
  username: string;
  role: 'free' | 'premium' | 'admin';
  filters: any[];  // Consider typing these more specifically
  charts: any[];
  liked_authors: string[];
  liked_products: string[];
  liked_sources: string[];
  subscriptions: any[];
  bio?: string;
  avatar_url?: string;
  updated_at?: string;
}

export type AccessCodes = {
  id: string;
  api_key: string | null;
  claimed: boolean;
  claimed_at: Date | null;
  email: string | null;
}

export const supabaseClient = createClient(supabaseUrl, supabaseAnonKey, {
    auth: {
        persistSession: true
    },
    db: {
        schema: 'public'
    }
})

export const signUpUser = async (email: string, password: string, accessCode: string) => {
    const { data: authData, error: authError } = await supabaseClient.auth.signUp({
        email,
        password,
        options: {
            emailRedirectTo: `${window.location.origin}/login`,
        }
    });

    if (authError) {
        console.error('Authentication error:', authError);
        throw authError;
    }

    if (!authData.user) {
        throw new Error('User creation failed - no user data returned');
    }

    const newUser: Omit<TrussUsers, 'updated_at'> = {
        id: authData.user.id,
        api_key: accessCode,
        username: email.split('@')[0],
        role: 'free',
        filters: [],
        charts: [],
        liked_authors: [],
        liked_products: [],
        liked_sources: [],
        subscriptions: []
    };

    const { error: dbError } = await supabaseClient
        .from('truss_users')
        .insert([newUser]);

    if (dbError) {
        console.error('Database error:', dbError);
        // Consider cleaning up the auth user if DB insert fails
        throw dbError;
    }

    return { user: authData.user, apiKey: accessCode };
};

export const fetchUserDataFromSupabase = async () => {
    const { data: { user } } = await supabaseClient.auth.getUser();
    if (!user) throw new Error('No user found');

    const { data: userData, error: userError } = await supabaseClient
        .from('truss_users')
        .select('*')
        .eq('id', user.id)
        .single();

    if (userError) {
        console.error('Error fetching user:', userError);
        throw userError;
    }

    if (!userData) {
        throw new Error('User data not found');
    }

    return {
        username: userData.username,
        bio: userData.bio || '',
        api_key: userData.api_key,
        avatar_url: userData.avatar_url || ''
    };
};

export const updateUserDataInSupabase = async (updates: {
    username?: string;
    bio?: string;
    avatarUrl?: string;
}) => {
    const { data: { user }, error: userError } = await supabaseClient.auth.getUser();
    if (userError) throw userError;
    if (!user) throw new Error('No user found');

    const supabaseUpdates: Partial<TrussUsers> = {
        username: updates.username,
        bio: updates.bio,
        avatar_url: updates.avatarUrl,
        updated_at: new Date().toISOString()
    };

    // Remove undefined values
    const filteredUpdates = Object.fromEntries(
        Object.entries(supabaseUpdates).filter(([_, value]) => value !== undefined)
    ) as Partial<TrussUsers>;

    const { error: supabaseError, data } = await supabaseClient
        .from('truss_users')
        .update(filteredUpdates)
        .eq('id', user.id)
        .select()
        .single();

    if (supabaseError) {
        console.error('Update error:', supabaseError);
        throw supabaseError;
    }

    return data;
};

export const updateUserPassword = async (newPassword: string) => {
    const { error } = await supabaseClient.auth.updateUser({
        password: newPassword
    });

    if (error) throw error;
};

export const testDatabaseAccess = async () => {
    const { data, error } = await supabaseClient
        .from('truss_users')
        .select('count');
    
    console.log('Test query result:', { data, error });
};

export const checkAccessCode = async (code: string): Promise<AccessCodes | null> => {
    const { data, error } = await supabaseClient
        .from('access_codes')
        .select('*')
        .eq('api_key', code)
        .eq('claimed', false)  // Only get unclaimed codes
        .single();

    if (error) {
        if (error.code === 'PGRST116') {  // No rows returned
            return null;  // Code doesn't exist or is already claimed
        }
        throw new Error(`Error checking access code: ${error.message}`);
    }
    return data;
}

export const claimAccessCode = async (code: string, email: string): Promise<AccessCodes | null> => {
    // First verify the code exists and is unclaimed
    const accessCode = await checkAccessCode(code);
    if (!accessCode) {
        throw new Error('Invalid or already claimed access code');
    }

    try {
        const { data, error } = await supabaseClient
            .from('access_codes')
            .update({ 
                claimed: true, 
                claimed_at: new Date().toISOString(), 
                email 
            })
            .eq('api_key', code)
            .select()
            .single();

        if (error) {
            console.error('Detailed error:', error);  // Add detailed error logging
            throw error;
        }
        
        return data;
    } catch (err) {
        console.error('Claim attempt failed:', err);  // Add catch block for debugging
        throw err;
    }
} 