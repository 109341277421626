import React from 'react';
import { useDarkMode } from '../hooks/useDarkMode';

interface ChartSelectorProps {
    selectedChart: string;
    onSelectChart: (chartType: string) => void;
}

const ChartSelector: React.FC<ChartSelectorProps> = ({ selectedChart, onSelectChart }) => {
    const { isDarkMode } = useDarkMode();

    return (
        <select
            value={selectedChart}
            onChange={(e) => onSelectChart(e.target.value)}
            className={`
                w-full p-2 rounded-md border 
                ${isDarkMode 
                    ? 'bg-gray-800 text-gray-200 border-gray-700' 
                    : 'bg-white text-gray-900 border-gray-300'
                }
                hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500
                transition-colors duration-200
            `}
        >
            <option value="pie">Pie Chart</option>
            <option value="bar">Bar Chart</option>
            <option value="heatmap">Heatmap</option>
            <option value="treemap">Treemap</option>
            <option value="funnel">Funnel</option>
        </select>
    );
};

export default ChartSelector;