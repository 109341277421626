import { useState, useMemo } from 'react';

export const usePagination = (totalPages: number, initialPage: number = 1, setCurrentPage: (page: number) => void) => {
  const [currentPage, setPage] = useState(initialPage);

  const pageNumbers = useMemo(() => {
    const numbers = [];
    const leftSiblingIndex = Math.max(currentPage - 1, 1);
    const rightSiblingIndex = Math.min(currentPage + 1, totalPages);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2;
      for (let i = 1; i <= leftItemCount; i++) {
        numbers.push(i);
      }
      numbers.push('ellipsis');
      numbers.push(totalPages);
    } else if (shouldShowLeftDots && !shouldShowRightDots) {
      numbers.push(1);
      numbers.push('ellipsis');
      const rightItemCount = 3 + 2;
      for (let i = totalPages - rightItemCount + 1; i <= totalPages; i++) {
        numbers.push(i);
      }
    } else if (shouldShowLeftDots && shouldShowRightDots) {
      numbers.push(1);
      numbers.push('ellipsis');
      for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
        numbers.push(i);
      }
      numbers.push('ellipsis');
      numbers.push(totalPages);
    } else {
      for (let i = 1; i <= totalPages; i++) {
        numbers.push(i);
      }
    }

    return numbers;
  }, [currentPage, totalPages]);

  const goToPage = (page: number) => {
    setPage(page);
    setCurrentPage(page);
  };

  const goToPreviousPage = () => {
    goToPage(Math.max(currentPage - 1, 1));
  };

  const goToNextPage = () => {
    goToPage(Math.min(currentPage + 1, totalPages));
  };

  return {
    currentPage,
    totalPages,
    pageNumbers,
    goToPage,
    goToPreviousPage,
    goToNextPage
  };
};
