import { create } from 'zustand'
import { DateRange } from '../shared/types';

interface DateRangeStore {
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  isInitialized: boolean;
  setInitialized: (value: boolean) => void;
}

export const useDateRangeStore = create<DateRangeStore>((set) => ({
  dateRange: {
    from: new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() - 7)),
    to: new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())),
  },
  isInitialized: false,
  setInitialized: (value: boolean) => set({ isInitialized: value }),
  setDateRange: (dateRange: DateRange) => {
    console.log('Date range being set:', dateRange);
    console.trace();
    const newRange = {
      from: new Date(dateRange.from),
      to: new Date(dateRange.to)
    };
    set({ dateRange: newRange });
  },
}));
