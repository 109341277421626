// ./stores/eChartStore.ts
import { EChartsOption } from 'echarts';

const defaultColors = [
    '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de',
    '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'
];

// Utility function to validate and return a solid color or fallback to black.
const validateColor = (color: string | undefined): string => {
    const isValidHex = /^#([0-9A-F]{3}){1,2}$/i.test(color || '');
    return isValidHex ? color as string : '#000000'; // Default to black if invalid or undefined
};

// Function to get a color from the default palette or fallback to a valid color.
const getColor = (index: number): string => {
    const color = defaultColors[index % defaultColors.length];
    return validateColor(color);
};

// Pie Chart Data Creator
export const createPieChartData = (
    data: Record<string, number>,
    label: string,
    width: number = 300,
    height: number = 300
): EChartsOption => ({
    title: {
        text: label,
        left: 'center',
    },
    tooltip: {
        trigger: 'item',
    },
    legend: {
        top: 'bottom',
    },
    series: [
        {
            name: label,
            type: 'pie',
            radius: `${Math.min(width, height) / 3}px`,
            avoidLabelOverlap: false,
            data: Object.entries(data).map(([key, value], index) => ({
                name: key,
                value,
                itemStyle: {
                    color: getColor(index),
                },
            })),
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
            },
        },
    ],
});

// Bar Chart Data Creator
export const createBarChartData = (
    data: Record<string, number>,
    label: string,
    width: number = 300,
    height: number = 300
): EChartsOption => ({
    title: {
        text: label,
        left: 'center',
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
        },
    },
    grid: {
        left: '10%',
        right: '10%',
        bottom: '20%',
        containLabel: true,
        width: width - 40, // Adjust grid to fit within the given width
        height: height - 60, // Adjust grid to fit within the given height
    },
    xAxis: {
        type: 'category',
        data: Object.keys(data),
        axisLabel: { rotate: 45 },
    },
    yAxis: {
        type: 'value',
    },
    series: [
        {
            name: label,
            type: 'bar',
            data: Object.values(data),
            itemStyle: {
                color: (params) => getColor(params.dataIndex),
                borderRadius: [5, 5, 0, 0],
            },
        },
    ],
});

// Heatmap Chart Data Creator
export const createHeatmapChartData = (
    data: Record<string, number>,
    label: string,
    width: number = 300,
    height: number = 300
): EChartsOption => ({
    title: {
        text: label,
        left: 'center',
    },
    tooltip: {
        position: 'top',
    },
    grid: {
        width: width * 0.8, // Adjusts grid width based on provided width
        height: height * 0.7, // Adjusts grid height based on provided height
        left: '10%',
        bottom: '20%',
        containLabel: true,
    },
    xAxis: {
        type: 'category',
        data: Object.keys(data),
        splitArea: {
            show: true,
        },
    },
    yAxis: {
        type: 'category',
        data: ['Counts'],
        splitArea: {
            show: true,
        },
    },
    visualMap: {
        min: 0,
        max: Math.max(...Object.values(data)),
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: '15%',
        inRange: {
            color: defaultColors,
        },
        outOfRange: {
            color: '#f0f0f0',
        },
    },
    series: [
        {
            name: label,
            type: 'heatmap',
            data: Object.entries(data).map(([key, value]) => [key, 'Counts', value]),
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
            },
        },
    ],
});

// Treemap Chart Data Creator
export const createTreemapChartData = (
    data: Record<string, number>,
    label: string,
    width: number = 300,
    height: number = 300
): EChartsOption => ({
    title: {
        text: label,
        left: 'center',
    },
    tooltip: {
        trigger: 'item',
        formatter: '{b}: {c}',
    },
    series: [
        {
            name: label,
            type: 'treemap',
            width,
            height,
            data: Object.entries(data).map(([key, value], index) => ({
                name: key,
                value,
                itemStyle: {
                    color: getColor(index),
                },
            })),
            leafDepth: 1,
            label: {
                show: true,
                formatter: '{b}',
            },
        },
    ],
});

// Funnel Chart Data Creator
export const createFunnelChartData = (
    data: Record<string, number>,
    label: string,
    width: number = 300,
    height: number = 300
): EChartsOption => ({
    title: {
        text: label,
        left: 'center',
    },
    tooltip: {
        trigger: 'item',
        formatter: '{b}: {c}',
    },
    series: [
        {
            name: label,
            type: 'funnel',
            width: `${width - 40}px`, // Adjust to fit within specified width
            height: `${height - 60}px`, // Adjust to fit within specified height
            data: Object.entries(data).map(([key, value], index) => ({
                name: key,
                value,
                itemStyle: {
                    color: getColor(index),
                },
            })),
        },
    ],
});