import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { generateCustomPaletteColors } from '../../shared/colorUtils';
import { useFilterStore } from '../../stores/useFilterStore';
import { ProductAttribute } from '../../shared/types';

interface TreemapChartProps {
    data: any;
    attributeKey: ProductAttribute;
}

const TreemapChart: React.FC<TreemapChartProps> = ({ data, attributeKey }) => {
    const { addFilter } = useFilterStore();
    const [renderKey, setRenderKey] = useState(0);

    const seriesData = (data?.series && data.series[0]?.data
        ? data.series[0].data
        : []
    ).filter((item: any) => item.name);

    const colors = generateCustomPaletteColors(seriesData.length);

    const chartOptions = {
        legend: { show: false },
        color: colors,
        series: [
            {
                name: 'Treemap',
                type: 'treemap',
                data: seriesData.map((item: any, index: number) => ({
                    name: item.name,
                    value: item.value,
                    itemStyle: {
                        color: colors[index % colors.length],
                    },
                })),
                leafDepth: 1,
            },
        ],
        tooltip: {
            trigger: 'item',
            formatter: (params: any) => `${params.name}: ${params.value}`,
        },
    };

    // Double-click handler
    const onEvents = {
        dblclick: (params: any) => {
            if (params && params.name) {
                addFilter(attributeKey, params.name); // Add filter on double-click
            }
        },
    };

    const handleRefresh = () => setRenderKey(prevKey => prevKey + 1);

    return (
        <div>
            <button onClick={handleRefresh}>Refresh Chart</button>
            <ReactECharts
                key={renderKey}
                option={chartOptions}
                style={{ height: 300 }}
                onEvents={onEvents} // Attach onEvents to handle double-clicks
            />
        </div>
    );
};

export default TreemapChart;