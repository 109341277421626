import React from 'react';
import ReactECharts from 'echarts-for-react';
import { generateCustomPaletteColors } from '../../shared/colorUtils';
import { useFilterStore } from '../../stores/useFilterStore';
import { ProductAttribute } from '../../shared/types';

interface HeatmapChartProps {
    data: any;
    attributeKey: ProductAttribute;
}

const HeatmapChart: React.FC<HeatmapChartProps> = ({ data, attributeKey }) => {
    const { addFilter } = useFilterStore();
    const xAxisData = data?.xAxis?.data || [];
    const seriesData = data?.series ? data.series[0].data : [];
    const colors = generateCustomPaletteColors(5).reverse();

    // Process `seriesData` without adding `name` to prevent it from appearing in legend
    const processedSeriesData = seriesData.map((item: any, index: number) => [
        xAxisData[index] || `Item ${index + 1}`,
        'Counts',
        item[2] || 0
    ]);

    const chartOptions = {
        legend: { show: false },
        visualMap: {
            min: data[2]?.min || 0,
            max: data[2]?.max || 100,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: 0,
            inRange: { color: colors },
            outOfRange: { color: '#f0f0f0' },
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
            axisLabel: { show: true },
            splitArea: { show: true },
        },
        yAxis: {
            type: 'category',
            axisLabel: { show: false },
            splitArea: { show: true },
        },
        series: [
            {
                name: 'Heatmap',
                type: 'heatmap',
                data: processedSeriesData,
                colorMappingBy: 'value',
                itemStyle: {
                    borderColor: '#ffffff',
                    borderWidth: 1,
                },
            },
        ],
        tooltip: {
            position: 'top',
            formatter: (params: any) => `${params.value[0]}: ${params.value[2]}`,
        },
    };

    const onEvents = {
        dblclick: (params: any) => {
            if (params && params.value) {
                addFilter(attributeKey, params.value[0]);
            }
        },
    };

    return <ReactECharts option={chartOptions} style={{ height: 300 }} onEvents={onEvents} />;
};

export default HeatmapChart;