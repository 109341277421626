import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import InsightsPage from './pages/InsightsPage';
import ProductsPage from './pages/ProductsPage';
import Chart from './pages/ChartsPage';
import MyAccountPage from './pages/MyAccountPage';
import ResourcesPage from './pages/ResourcesPage';
import ApiPage from './pages/ApiPage';
import Signout from './pages/Signout';
import { useAuth } from './context/AuthContext';
import AppSidebarLayout from './components/AppSidebarLayout';
import { useDateRangeStore } from './stores/useDateRangeStore';
import { useAttributeCountStore } from './stores/useAttributeCountStore';
import BillingPage from './pages/BillingPage';
import SavedFiltersPage from './pages/SavedFiltersPage';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const { isAuthenticated, authLoading } = useAuth();
    
    if (authLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900">
                <div className="text-gray-600 dark:text-gray-300">Loading...</div>
            </div>
        );
    }
    
    return isAuthenticated ? children : <Navigate to="/login" />;
};

const App: React.FC = () => {
    const { isInitialized, setInitialized } = useDateRangeStore();
    const fetchAttributeCounts = useAttributeCountStore(state => state.fetchAttributeCounts);

    useEffect(() => {
        if (!isInitialized) {
            setInitialized(true);
            //fetchAttributeCounts();
        }
    }, [isInitialized, setInitialized, fetchAttributeCounts]);

    return (
        <div className="h-screen flex">
            <AppSidebarLayout>
                <Routes>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signout" element={<Signout />} />
                    <Route path="/insights" element={<PrivateRoute><InsightsPage /></PrivateRoute>} />
                    <Route path="/products" element={<PrivateRoute><ProductsPage /></PrivateRoute>} />
                    <Route path="/charts" element={<PrivateRoute><Chart /></PrivateRoute>} />
                    <Route path="/api" element={<PrivateRoute><ApiPage /></PrivateRoute>} />
                    <Route path="/my-account" element={<PrivateRoute><MyAccountPage /></PrivateRoute>} />
                    <Route path="/resources" element={<PrivateRoute><ResourcesPage /></PrivateRoute>} />
                    <Route path="/billing" element={<PrivateRoute><BillingPage /></PrivateRoute>} />
                    <Route path="/saved-filters" element={<PrivateRoute><SavedFiltersPage /></PrivateRoute>} />
                </Routes>
            </AppSidebarLayout>
        </div>
    );
};

export default App;